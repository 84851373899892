/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { FaSignInAlt, FaCheckCircle } from 'react-icons/fa';
import Modal from '../Modal';
import { Form } from '@unform/web';
import Input from '../Input';
import Radio from '../Radio';
import Select from '../Select';
import ModalLoader from '../ModalLoader';
import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';
import Logo from '../../assets/logo_plataforma_novo.png';
import Bid from '../../assets/logo-bid-no-background.png';
import SelectOptions from './options';
import { Container } from './styles';

const schema = Yup.object().shape({
  institutional_mail: Yup.string()
    .email('digite um email válido')
    .required('email institucional é obrigatório'),
  full_name: Yup.string().required('Nome é obrigatório'),
  location: Yup.string().required('Esse campo é obrigatório'),
  password: Yup.string().required('Senha é obrigatória'),
  confirm_password: Yup.string().required('Senha é obrigatória'),
});

function RegisterModal({ show, handleClose }) {
  const formRef = useRef(null);
  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState('estado');
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const RadioOptions = [
    { id: 'estado', label: 'Estado' },
    { id: 'municipio', label: 'Município' },
  ];

  const handleChange = useCallback(ev => {
    if (ev.target.type === 'radio') {
      setSelectedOption(ev.target.value);
      formRef.current.setFieldValue('place_acting', '');
    }
  }, []);

  const handleSubmit = useCallback(async data => {
    try {
      formRef.current?.setErrors({});
      setError('');

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      await api.post('/accounts/register/', data);

      setLoading(false);
      setSuccessMessage(true);
    } catch (err) {
      const { ...error } = err;
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
      setLoading(false);
      setError(
        'Ocorreu um erro ao tentar cadastrar. Tente novamente ou entre em contato com o suporte.',
      );
      console.error('err', error);
    }
  }, []);

  useEffect(() => {
    formRef.current.setFieldValue('place_acting', 'estado');
  }, []);

  return (
    <Container>
      <Modal
        title="Login"
        success={false}
        successText=""
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <div className="loginContainer">
            <div className="infoContainer">
              <div className="text">
                <h1>Acesso às linhas de financiamento</h1>
                <small>
                  O PROSEG Federativo é uma parceria entre o Banco
                  Interamericano de Desenvolvimento (BID) e o Banco Nacional de
                  Desenvolvimento (BNDES) que busca apoiar estados e municípios
                  a fortalecer os mecanismos de planejamento, governança e
                  gestão dos projetos de segurança pública através de linhas de
                  crédito específicas para a área.
                </small>
              </div>
              <div className="logo1">
                <img src={Logo} alt="" />
              </div>
              <div className="logo2">
                <img src={Bid} alt="" />
              </div>
            </div>

            {successMessage ? (
              <div className="formContainer">
                <h1 className="successTitle">
                  Cadastro solicitado com sucesso
                </h1>
                <small>
                  Enviamos um link de confirmação para o seu e-mail.
                </small>
                <FaCheckCircle size={100} color="#00a9ee" />
              </div>
            ) : (
              <div className="formContainer">
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                >
                  <h1>Cadastre-se</h1>
                  <small>
                    Cadastro exclusivo para gestores públicos interessados em
                    construir, através da plataforma, suas pré-propostas de
                    financiamento junto ao BID/BNDES.
                  </small>

                  <div className="inputContainer">
                    <Input
                      RegisterModal
                      id="nome"
                      name="full_name"
                      className="input"
                      placeholder="Nome"
                    />
                  </div>
                  <div className="radioContainer">
                    <small>Você atua em Estado ou Município?</small>
                    <Radio
                      name="place_acting"
                      options={RadioOptions}
                      defaultValue={{ id: 'estado', label: 'Estado' }}
                    />
                  </div>
                  <div className="selectContainer">
                    {selectedOption === 'estado' ? (
                      <Select
                        name="location"
                        options={SelectOptions.estados.sort(function (a, b) {
                          if (a.label > b.label) {
                            return 1;
                          }
                          if (a.label < b.label) {
                            return -1;
                          }
                          return 0;
                        })}
                        label="Estado"
                        placeholder="Veja a lista de Estados"
                        schema={schema}
                      />
                    ) : (
                      <Select
                        name="location"
                        options={SelectOptions.municipios.sort(function (a, b) {
                          if (a.label > b.label) {
                            return 1;
                          }
                          if (a.label < b.label) {
                            return -1;
                          }
                          return 0;
                        })}
                        label="Municipio"
                        placeholder="Veja a lista de Municipios"
                        schema={schema}
                      />
                    )}
                  </div>
                  <div className="inputContainer">
                    <Input
                      RegisterModal
                      id="institutional_mail"
                      name="institutional_mail"
                      className="input"
                      placeholder="Email Institucional"
                    />
                  </div>
                  <div className="inputContainer">
                    <Input
                      RegisterModal
                      id="password"
                      name="password"
                      type="password"
                      className="input"
                      placeholder="Senha"
                    />
                  </div>
                  <div className="inputContainer">
                    <Input
                      RegisterModal
                      id="confirm_password"
                      name="confirm_password"
                      type="password"
                      className="input"
                      placeholder="Confirmação de senha"
                    />
                  </div>
                  {error && <div className="errormessage">{error}</div>}
                  <div className="termsContainer">
                    <p>
                      Ao inscrever-se, concordo com os &nbsp;
                      <a href="/termos-de-uso/" target="_blank">
                        Termos de Serviço
                      </a>
                      &nbsp; e &nbsp;
                      <a href="/politicas-de-privacidade/" target="_blank">
                        Política de Privacidade
                      </a>
                      &nbsp; da Plataforma de Segurança - BID BNDES.
                    </p>
                  </div>
                  <div className="submitContainer">
                    <button type="submit" className="submit">
                      <FaSignInAlt />
                      Enviar
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        )}
      </Modal>
    </Container>
  );
}

export default RegisterModal;
