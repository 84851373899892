/* eslint-disable */
import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import ModalLoader from '../../../../components/ModalLoader';
import getValidationErrors from '../../../../services/getValidationErrors';
import Select from '../../../../components/Select';
import Modal from '../../../../components/Modal';
import api from '../../../../services/api';
import { Container, ImpactItem } from './styles';

import { MdDelete } from 'react-icons/md';

const AddDesiredImpacts = ({
  show,
  handleClose,
  handleSuccess,
  handleDelete,
  creditOperation,
}) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [allImpacts, setAllImpacts] = useState([]);
  const [impacts, setImpacts] = useState([]);

  const schema = Yup.object().shape({
    impact: Yup.string().required('Impacto é obrigatório'),
  });

  const getAllImpacts = useCallback(async () => {
    try {
      const apiUrl = '/credits/api/v1/impacts/';
      const response = await api.get(apiUrl);
      setAllImpacts(response.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getImpacts = useCallback(async () => {
    try {
      const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/desired-impacts/`;
      const response = await api.get(apiUrl);
      setImpacts(response.data.results);
    } catch (err) {
      console.error(err);
    }
  }, [creditOperation]);

  const SelectOptions = useMemo(() => {
    const options = allImpacts.map(impact => ({
      value: impact.id,
      label: impact.name,
    }));

    return options;
  }, [allImpacts]);

  useEffect(() => {
    const Init = async () => {
      setLoading(true);
      await getAllImpacts();
      await getImpacts();
      setLoading(false);
    };
    Init();
  }, [getAllImpacts, getImpacts]);

  const handleAddImpact = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/desired-impacts/`;
        const response = await api.post(apiUrl, {
          impacts: data.impact,
          credit_operation: creditOperation.code,
        });
        setLoading(false);
        setImpacts([...impacts, response.data]);
        handleSuccess(response.data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);
      }
    },
    [impacts, schema, handleSuccess, creditOperation.code],
  );

  const handleDeleteImpact = useCallback(
    async data => {
      try {
        setLoading(true);
        const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/desired-impacts/${data.id}`;
        await api.delete(apiUrl);
        const removeIndex = impacts.map(impact => impact.id).indexOf(data.id);
        impacts.splice(removeIndex, 1);
        setImpacts([...impacts]);
        handleDelete(impacts);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [creditOperation.code, impacts, handleDelete],
  );

  return (
    <Container>
      <Modal
        title="Adicionar impactos Desejados"
        successText="impactos desejados adicionados com sucesso"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form ref={formRef} onSubmit={handleAddImpact}>
              <Select
                name="impact"
                options={SelectOptions}
                label="Impacto"
                placeholder="Selecione o Impacto que deseja adicionar"
                schema={schema}
              />
              {impacts.map(impacto => (
                <ImpactItem key={impacto.id}>
                  <span>{impacto.impact_indicator.impact_name}</span>
                  <button
                    type="button"
                    onClick={() => handleDeleteImpact(impacto)}
                  >
                    <MdDelete size={23} color="#FFFFFF" />
                  </button>
                </ImpactItem>
              ))}
              <button className="button" type="submit">
                Adicionar
              </button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default AddDesiredImpacts;
