import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';
import ModalLoader from '../../../../components/ModalLoader';
import ErrorModal from '../../../../components/ErrorModal';
import Modal from '../../../../components/Modal';
import UpdateResults from '../UpdateResults';
import AddRestriction from '../addRestriction';
import CriticalComments from '../CriticalComments';
import PendingRestrictions from '../PedingRestrictions';
import FinishCritical from '../../modals/FinishCritical';
import DeliverableAccordion from '../../../../components/DeliverableAccordion';
import StackedPercentageBar from '../../../../components/StackedPercentageBar';
import MultiPercentageBar from '../../../../components/MultiPercentageBar';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import DataTable from 'react-data-table-component';
import SuccessModal from '../../../../components/SuccessModal';
import {
  Container,
  CardTitle,
  Card,
  StatusSpan,
  GestaoButton,
  ButtonContainer,
  FinishButton,
  OptionsContainer,
} from './styles';
import {
  FaInfoCircle,
  FaCheckCircle,
  FaFlagCheckered,
  FaComments,
  FaTrash,
  FaEdit,
} from 'react-icons/fa';

const DeliverablesCriticalMilestones = ({
  show,
  handleClose,
  projectId,
  project,
  operation,
  handleGetProjects,
  handleGetCreditOperation,
  isUpdate,
  isCompleteDelivery,
  isEditComments,
  isPendingRestrictionsFinish
}) => {
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const [deliverables, setDeliverables] = useState([]);
  const [openDeliverableId, setOpenDeliverableId] = useState(null);
  const [actualDeliverable, setActualDeliverable] = useState({});
  const [multiPercentageData, setMultiPercentageData] = useState([]);
  const [editDeliverable, setEditDeliverable] = useState({});
  const [criticalMilestones, setCriticalMilestones] = useState([]);
  const [showUpdateResults, setShowUpdateResults] = useState(false);
  const [showAddRestriction, setShowAddRestriction] = useState(false);
  const [showCriticalComments, setShowCriticalComments] = useState(false);
  const [showFinishCritical, setShowFinishCritical] = useState(false);
  const [showPendingRestrictions, setShowPendingRestrictions] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [showDeleteDeliverableModal, setShowDeleteDeliverableModal] =
    useState(false);

  const [modalCritical, setModalCritical] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useAuth();

  const getDeliverables = async () => {
    setLoading(true);
    try {
      const deliverables = await api.get('/credits/api/v1/entregable/');
      const filteredDeliverables = deliverables.data.results.filter(
        deliv => deliv.contract_project === Number(projectId),
      );
      setDeliverables(filteredDeliverables);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getDeliverables = async () => {
      setLoading(true);
      try {
        const deliverables = await api.get('/credits/api/v1/entregable/');
        const filteredDeliverables = deliverables.data.results.filter(
          deliv => deliv.contract_project === Number(projectId),
        );
        setDeliverables(filteredDeliverables);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    getDeliverables();
  }, []);

  const addedOpenDeliverables = useMemo(() => {
    const editedDeliverable = deliverables.map(deliverable => {
      const addedOpenDeliverable = { ...deliverable, open: false };
      return addedOpenDeliverable;
    });

    return editedDeliverable;
  }, [deliverables]);

  const getCriticalMilestones = useCallback(async deliverable => {
    try {
      const response = await api.get(
        `/credits/api/v1/entregable/${deliverable.id}/critical/`,
      );
      setCriticalMilestones(response.data.results);
      GetMultiPercentageData(deliverable, response.data.results);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleAccordionOpen = useCallback(
    deliverable => {
      setActualDeliverable(deliverable);
      setOpenDeliverableId(deliverable.id);
      getCriticalMilestones(deliverable);
      addedOpenDeliverables.map(item => {
        deliverable.open === false
          ? item.id === deliverable.id
            ? (item.open = true)
            : (item.open = false)
          : (item.open = false);
        return item;
      });
    },
    [getCriticalMilestones, addedOpenDeliverables],
  );

  const handleFinishCritical = useCallback(async () => {
    try {
      const response = await api.get(
        `/credits/api/v1/entregable/${openDeliverableId}`,
      );
      setActualDeliverable(response.data);
      setOpenDeliverableId(response.data.id);
      getCriticalMilestones(response.data);
      handleGetProjects(operation.code);
      handleGetCreditOperation();
    } catch (err) {
      console.error(err);
    }
  }, [
    getCriticalMilestones,
    openDeliverableId,
    handleGetProjects,
    operation,
    handleGetCreditOperation,
  ]);

  const handleSetEditDeliverable = useCallback(deliverable => {
    setEditDeliverable(deliverable);
    setShowUpdateResults(true);
  }, []);

  const handleSetModalCritical = useCallback((critical, type) => {
    setModalCritical(critical);
    type === 'comments' && setShowCriticalComments(true);
    type === 'restriction' && setShowAddRestriction(true);
    type === 'finish' && setShowFinishCritical(true);
  }, []);

  const handleDeleteDeliverable = useCallback(
    async deliverable => {
      try {
        await api.delete(`/credits/api/v1/entregable/${deliverable.id}/`);
        getDeliverables();
      } catch (err) {
        console.error(err);
      }
    },
    [getDeliverables],
  );

  const handleDeleteCriticalMilestone = useCallback(
    async (deliverableId, criticalId) => {
      try {
        await api.delete(
          `/credits/api/v1/entregable/${deliverableId}/critical/${criticalId}`,
        );
        handleFinishCritical();
      } catch (err) {
        console.error(err);
      }
    },
    [handleFinishCritical],
  );

  const GetMultiPercentageData = useCallback((deliverable, CriticalData) => {
    const data = [
      {
        status: 'execution',
        percentage: deliverable.critical.execution,
        color: 'blue',
      },
      {
        status: 'finish',
        percentage: deliverable.critical.finish,
        color: 'green',
      },
      {
        status: 'delay',
        percentage: deliverable.critical.delay,
        color: 'red',
      },
      {
        status: 'restriction',
        percentage: deliverable.critical.restriction,
        color: 'yellow',
      },
      {
        status: 'delay_restriction',
        percentage: deliverable.critical.delay_restriction,
        color: 'brown',
      },
    ];
    setMultiPercentageData(data);
  }, []);

  const getStatusColor = color => {
    const status = {
      execution: 'blue',
      finalized: 'green',
      delay: 'red',
      restriction: 'yellow',
      delay_restriction: 'brown',
    };
    return status[color];
  };

  const getStatusName = name => {
    const status = {
      execution: 'Em execução',
      finalized: 'Concluído',
      delay: 'Em atraso',
      restriction: 'Com restrição',
      delay_restriction: 'Em atraso e com restrição',
    };
    return status[name];
  };

  const finishDeliverable = useCallback(async () => {
    try {
      setLoadingSuccess(true);
      setShowSuccess(true);
      await api.post(
        `/credits/api/v1/entregable/${actualDeliverable.id}/finish/`,
      );
      setLoadingSuccess(false);
    } catch (err) {
      setLoadingSuccess(false);
      setShowSuccess(false);
      console.error(err);
      const { ...error } = err;
      if (error.response.data.message) {
        setError(true);
        setErrorMessage(error.response.data.message);
      }
    }
  }, [actualDeliverable]);

  const columns = [
    {
      name: 'Marco Crítico',
      selector: 'name',
      minWidth: '0px',
    },
    {
      name: 'Ponto Focal',
      selector: 'responsible',
      minWidth: '0px',
      cell: row => <div style={{ width: '100%' }}>{row.responsible.name}</div>,
    },
    {
      name: 'Prazo',
      selector: 'deadline',
      minWidth: '0px',
      maxWidth: '120px',
      cell: row => (
        <div style={{ width: '100%' }}>
          {format(parseISO(row.deadline), 'dd/MM/yyyy')}
        </div>
      ),
    },
    {
      name: 'Status',
      selector: 'status',
      minWidth: '0px',
      maxWidth: '180px',
      cell: row => (
        <StatusSpan color={getStatusColor(row.status)}>
          {getStatusName(row.status)}
        </StatusSpan>
      ),
    },
    {
      name: 'Gestão',
      selector: 'id ',
      minWidth: '0px',
      cell: row => (
        <ButtonContainer>
          {!user.is_admin && operation.status === 'execution' && (
            <>
              <GestaoButton
                type="button"
                onClick={() => handleSetModalCritical(row, 'restriction')}
                color="yellow"
              >
                <span>R</span>
              </GestaoButton>
              <GestaoButton
                type="button"
                color="green"
                onClick={() => handleSetModalCritical(row, 'finish')}
              >
                <FaFlagCheckered size={25} color="#ffffff" />
              </GestaoButton>
            </>
          )}
          <GestaoButton
            type="button"
            onClick={() => handleSetModalCritical(row, 'comments')}
            color="blue"
          >
            <FaComments size={25} color="#ffffff" />
          </GestaoButton>
          {!user.is_admin &&
            operation.status !== 'execution' &&
            !operation.is_delete && (
              <GestaoButton
                type="button"
                color="red"
                onClick={() =>
                  handleDeleteCriticalMilestone(openDeliverableId, row.id)
                }
              >
                <FaTrash size={25} color="#ffffff" />
              </GestaoButton>
            )}
        </ButtonContainer>
      ),
    },
  ];

  return (
    <Container>
      <Modal
        title="Entregáveis"
        success={false}
        successText="teste"
        handleClose={handleClose}
        show={show}
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            {!!addedOpenDeliverables.length &&
              addedOpenDeliverables.map(deliverable => (
                <DeliverableAccordion
                  key={deliverable.id}
                  title={`${deliverable.title}`}
                  hasDelete={
                    !!(
                      operation.status === 'structuring' &&
                      !user.is_admin &&
                      !operation.is_delete
                    )
                  }
                  deleteCallBack={() => setShowDeleteDeliverableModal(true)}
                  active={deliverable.open}
                  onOpen={() => handleAccordionOpen(deliverable)}
                >
                  <div className="indicators-container">
                    <OptionsContainer
                      show={
                        !!(
                          operation.status === 'structuring' &&
                          !operation.is_delete
                        )
                      }
                    >
                      <div
                        onClick={() =>
                          push(
                            `/projects/projetos-contratados/${project.id}/entregaveis/${openDeliverableId}`,
                          )
                        }
                      >
                        <FaEdit />
                        Editar
                      </div>
                    </OptionsContainer>
                    <CardTitle>
                      <FaInfoCircle />
                      <b>Indicador de Produto :&nbsp;</b>
                      {deliverable.indicator}
                    </CardTitle>
                    <CardTitle>
                      <FaInfoCircle />
                      <b>Unidade de Medida:&nbsp;</b>
                      {deliverable.unit_measure}
                    </CardTitle>
                    <CardTitle>
                      <FaInfoCircle />
                      <b>Fonte:&nbsp;</b> {deliverable.source}
                    </CardTitle>
                    <CardTitle>
                      <FaInfoCircle />
                      <b>Prazo Final:&nbsp;</b>{' '}
                      {`${deliverable.deadline.split('-')[2]}/${
                        deliverable.deadline.split('-')[1]
                      }/${deliverable.deadline.split('-')[0]}`}
                    </CardTitle>
                    <CardTitle>
                      <FaInfoCircle />
                      <b>Meta:&nbsp;</b> {deliverable.goal}
                    </CardTitle>
                    <div className="FinalizeButtoncontainer">
                      <CardTitle>
                        <FaInfoCircle />
                        <b>Realizado:&nbsp;</b>
                        {actualDeliverable.realized_total}
                        {((!user.is_admin && !operation.is_delete) && isUpdate) && (
                          <button
                            type="button"
                            onClick={() =>
                              handleSetEditDeliverable(deliverable)
                            }
                          >
                            Atualizar
                          </button>
                        )}
                      </CardTitle>
                      {((!user.is_admin && !operation.is_delete) && isCompleteDelivery) && (
                        <FinishButton
                          type="button"
                          onClick={() => finishDeliverable()}
                        >
                          <FaCheckCircle size={26} /> Concluir Entrega
                        </FinishButton>
                      )}
                    </div>
                  </div>
                  <CardTitle style={{ marginTop: '20px' }}>
                    <b>Status Geral - Entrega </b>
                  </CardTitle>
                  <Card>
                    <StackedPercentageBar
                      percentage={actualDeliverable.execution_physic}
                      criticalLength={criticalMilestones.length}
                    />
                  </Card>
                  <CardTitle>
                    <b>Status Geral (Marcos Críticos) </b>
                  </CardTitle>
                  <Card>
                    <MultiPercentageBar data={multiPercentageData} />
                  </Card>
                  <DataTable
                    columns={columns}
                    data={criticalMilestones}
                    noDataComponent="Nenhum Marco Crítico Cadastrado"
                    noHeader
                  />
                  {!operation.is_delete && (
                    <div className="buttons-container">
                      <button
                        type="button"
                        onClick={() => setShowPendingRestrictions(true)}
                      >
                        Ver Restrições Pendentes
                      </button>
                    </div>
                  )}
                </DeliverableAccordion>
              ))}

            {addedOpenDeliverables.length === 0 && (
              <>
                {!user.is_admin && operation.status === 'structuring' && (
                  <h1 className="emptyDeliverable">
                    Nenhum Entregável adicionado a esse projeto &nbsp;
                    <button
                      type="button"
                      onClick={() =>
                        push(
                          `/projects/projetos-contratados/${projectId}/entregaveis`,
                        )
                      }
                    >
                      clique aqui
                    </button>
                    &nbsp; para adicionar.
                  </h1>
                )}
                {user.is_admin && (
                  <h1 className="emptyDeliverable">
                    Nenhum Entregável adicionado a esse projeto
                  </h1>
                )}
                {!user.is_admin && operation.status === 'execution' && (
                  <h1 className="emptyDeliverable">
                    Nenhum Entregável adicionado a esse projeto
                  </h1>
                )}
              </>
            )}
          </>
        )}
      </Modal>
      {showUpdateResults && (
        <UpdateResults
          handleClose={() => setShowUpdateResults(!showUpdateResults)}
          handleSuccess={handleFinishCritical}
          show={showUpdateResults}
          deliverable={actualDeliverable}
          project={project}
          operation={operation}
        />
      )}
      {showAddRestriction && (
        <AddRestriction
          handleClose={() => setShowAddRestriction(!showAddRestriction)}
          handleSuccess={handleFinishCritical}
          show={showAddRestriction}
          deliverable={actualDeliverable}
          critical={modalCritical}
        />
      )}
      {showCriticalComments && (
        <CriticalComments
          isEditComments={isEditComments}
          handleClose={() => setShowCriticalComments(!showCriticalComments)}
          show={showCriticalComments}
          critical={modalCritical}
          deliverableId={openDeliverableId}
        />
      )}
      {showFinishCritical && (
        <FinishCritical
          handleClose={() => setShowFinishCritical(!showFinishCritical)}
          handleSuccess={data => handleFinishCritical(data)}
          show={showFinishCritical}
          critical={modalCritical}
        />
      )}
      {showPendingRestrictions && (
        <PendingRestrictions
          handleClose={() =>
            setShowPendingRestrictions(!showPendingRestrictions)
          }
          handleSuccess={data => handleFinishCritical(data)}
          show={showPendingRestrictions}
          criticals={criticalMilestones}
          isPendingRestrictionsFinish={isPendingRestrictionsFinish}
        />
      )}
      {error && (
        <ErrorModal
          show={error}
          Message={errorMessage}
          handleClose={() => setError(false)}
        />
      )}
      {showSuccess && (
        <SuccessModal
          loading={loadingSuccess}
          handleClose={() => setShowSuccess(false)}
          show={showSuccess}
          successText="Entregável finalizado!!"
        />
      )}
      {showDeleteDeliverableModal && (
        <ConfirmationModal
          handleClose={() =>
            setShowDeleteDeliverableModal(!showDeleteDeliverableModal)
          }
          handleSuccess={() => handleDeleteDeliverable(actualDeliverable)}
          show={showDeleteDeliverableModal}
          title="Você tem certeza que deseja excluir esse entregável?"
        />
      )}
    </Container>
  );
};

export default DeliverablesCriticalMilestones;
