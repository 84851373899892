import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import jsPDF from 'jspdf';
import api from '../../../services/api';
import { formatPrice } from '../../../services/currencyFormatter';
import DataTable from 'react-data-table-component';
import Loader from '../../../components/Loader';
import PrintLoader from '../../../components/PrintLoader';
import DonutChart from '../../../components/DonutChart';
import Donut from '../../../components/Donut';
import StackedBar from './graphics/StackedBar';
import MultiBar from './graphics/MultiBar';
import MultiVerticalBar from './graphics/MultiVerticalBar';
import Line from './graphics/Line';
import { printDocumentPage, getPageCanvas } from './reportService';
import Logo from '../../../assets/logo-bid.png';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Container,
  Header,
  HeaderContent,
  Main,
  MainContent,
  PrintDiv,
  Card,
  Title,
  GraphicsContainer,
  DonutContainer,
} from './styles';
import { Row, Column } from '../../../styles/components';

const ExecutiveReport2 = () => {
  const printRef = useRef(null);
  const [reportData, setReportData] = useState({});
  const [printing, setPrinting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`credits/api/v1/report-overview/${window.location.search}`)
      .then(response => {
        setReportData(response.data);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      name: 'Carteira',
      selector: 'wallet',
      minWidth: '82%',
    },
    {
      name: 'Quant.',
      selector: 'quantity',
      minWidth: '0px',
    },
  ];

  const tableData = useMemo(() => {
    let arrReturn = [];
    if (Object.keys(reportData).length) {
      arrReturn = [
        {
          wallet: 'Operações contratadas',
          quantity: reportData.overview_vision.contracted_operation,
        },
        {
          wallet: 'Resultados pactuados',
          quantity: reportData.overview_vision.agreed_results,
        },
        {
          wallet: 'Projetos contratados (soluções)',
          quantity: reportData.overview_vision.contracted_projects,
        },
        {
          wallet: 'Entregas',
          quantity: reportData.overview_vision.entregables,
        },
        {
          wallet: 'Marcos críticos',
          quantity: reportData.overview_vision.critical,
        },
        {
          wallet: 'Valor global (mil R$)',
          quantity: formatPrice(reportData.overview_vision.global_value),
        },
        {
          wallet: 'Valor executado (mil R$)',
          quantity: formatPrice(reportData.overview_vision.execution_value),
        },
      ];
    }
    return arrReturn;
  }, [reportData]);

  const printDocument = useCallback(async () => {
    window.scrollTo(0, 0);
    setPrinting(true);
    setTimeout(async () => {
      const page = await getPageCanvas(printRef.current);
      const pdf = new jsPDF('pt', 'mm');
      await printDocumentPage(page, pdf, false);
      window.open(pdf.output('bloburl'));
      setPrinting(false);
    }, 500);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <PrintDiv printing={printing} ref={printRef}>
          {printing && <PrintLoader />}
          <Header>
            <HeaderContent printing={printing}>
              <div className="optionsContainer">
                <button
                  className="back"
                  type="button"
                  onClick={() => printDocument()}
                >
                  Imprimir
                </button>
              </div>
              <div className="content">
                <div className="textContent">
                  <h1>Relatório Executivo</h1>
                  <h2>Visão Global da Carteira</h2>
                </div>
                <div className="left-container">
                  <div className="img-container">
                    <img src={Logo} alt="" />
                  </div>
                </div>
              </div>
            </HeaderContent>
          </Header>
          <Main>
            <MainContent>
              <Title>
                <div className="iconContainer">
                  <IoIosArrowForward color="#ffffff" size={18} />
                </div>
                Visão geral
              </Title>
              <Card printing={printing}>
                <Row>
                  <Column small="12" medium="12" large="12">
                    <DataTable columns={columns} data={tableData} noHeader />
                  </Column>
                </Row>
              </Card>
              <Title>
                <div className="iconContainer">
                  <IoIosArrowForward color="#ffffff" size={18} />
                </div>
                Execução físico-financeira
              </Title>
              <Card printing={printing}>
                <Row>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="border-dashed"
                  >
                    <GraphicsContainer>
                      <h1>Resultados (%)</h1>
                      <div className="graphicContainer1">
                        <StackedBar
                          data={
                            reportData.execution_physic_finance.agreed_result
                              .agreed_result
                          }
                        />
                      </div>
                      <div className="graphicContainer2">
                        <MultiBar
                          data={
                            reportData.execution_physic_finance.agreed_result
                              .location
                          }
                        />
                      </div>
                    </GraphicsContainer>
                  </Column>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="border-dashed"
                  >
                    <GraphicsContainer>
                      <h1>Entregas (%)</h1>
                      <div className="graphicContainer1">
                        <StackedBar
                          data={
                            reportData.execution_physic_finance.entregables
                              .entregables
                          }
                        />
                      </div>
                      <div className="graphicContainer2">
                        <MultiBar
                          data={
                            reportData.execution_physic_finance.entregables
                              .location
                          }
                        />
                      </div>
                    </GraphicsContainer>
                  </Column>
                  <Column small="4" medium="4" large="4">
                    <GraphicsContainer>
                      <h1>Financeiro (%)</h1>
                      <div className="graphicContainer1">
                        <StackedBar
                          financial
                          data={
                            reportData.execution_physic_finance.financial
                              .financial
                          }
                        />
                      </div>
                      <div className="graphicContainer2">
                        <MultiBar
                          financial
                          data={
                            reportData.execution_physic_finance.financial
                              .location
                          }
                        />
                      </div>
                    </GraphicsContainer>
                  </Column>
                </Row>
              </Card>

              <Row>
                <Column small="7" medium="7" large="7">
                  <Title>
                    <div className="iconContainer">
                      <IoIosArrowForward color="#ffffff" size={18} />
                    </div>
                    Situação atual dos marcos críticos
                  </Title>
                  <Card printing={printing}>
                    <MultiVerticalBar data={reportData.info_critical} />
                  </Card>
                </Column>

                <Column small="5" medium="5" large="5">
                  <Title>
                    <div className="iconContainer">
                      <IoIosArrowForward color="#ffffff" size={18} />
                    </div>
                    Índices de desempenho
                  </Title>
                  <Card printing={printing} className="desenpenhoCard">
                    <Line
                      color="#014D90"
                      middleValue={0.6}
                      small="Valor médio do PROSEG: 0,6"
                      value={reportData.info_performance.idp}
                      title="IDP (Índice de Desempenho do Prazo)"
                      dataTip=" Para calcular o IDP de cada operação, deve-se, primeiro, calcular o IDP de cada entrega vinculada à referida operação. Isto é, deve-se, para cada entrega dividir o total de entregas efetivadas até o último mês completo (soma dos valores “realizados”) pelo valor previsto até o último mês completo (hoje esse mês seria maio). O cálculo do IDP de cada operação deve ser calculado a partir da média aritmética simples dos IDP’s das entregas vinculadas a essa operação (isto é, soma dos IDP’s de cada entrega / número de entregas vinculadas à operação). Já o IDP médio de todas as operações é calculado a partir da média aritmética simples (soma dos IDP’s médios de cada operação / número de operações cadastradas como estando em “estágio de execução” na plataforma)."
                    />
                    <Line
                      color="#014D90"
                      middleValue={0.8}
                      small="Valor médio do PROSEG: 0,8"
                      value={reportData.info_performance.idf}
                      title="IDF (Índice de Desempenho Financeiro)"
                      dataTip="Para calcular o IDF de cada operação deve-se dividir o montante financeiro pago até o último mês completo (soma dos valores “pagos”) pela soma do montante previsto até o último mês completo (hoje esse mês seria maio). Já o IDF médio de todas as operações é calculado a partir da média aritmética simples (soma dos IDF’s de cada operação / número de operações cadastradas como estando em “estágio de execução” na plataforma)."
                    />
                  </Card>
                </Column>
              </Row>

              <Title>
                <div className="iconContainer">
                  <IoIosArrowForward color="#ffffff" size={18} />
                </div>
                Indicadores Estratégicos
              </Title>
              <Row>
                <Column small="3" medium="3" large="3">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>
                        {
                          reportData.info_indicator.tx_datasus_viol_dom_crianca
                            .name
                        }
                      </h1>
                      <Donut
                        value={
                          reportData.info_indicator.tx_datasus_viol_dom_crianca
                            .avg
                        }
                        color="#075E89"
                        strokeWidth="13"
                        className="donut"
                      />
                      {!!reportData.info_indicator.tx_datasus_viol_dom_crianca
                        .avg_country && (
                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={
                              reportData.info_indicator
                                .tx_datasus_viol_dom_crianca.avg_country
                            }
                            color="#6D6E71"
                            size={50}
                            fontSize="10"
                            strokewidth={7}
                          />
                          <small>Demais Estados</small>
                        </div>
                      )}
                    </DonutContainer>
                  </Card>
                </Column>
                <Column small="3" medium="3" large="3">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>
                        {reportData.info_indicator.tx_forum_seg_cvli.name}
                      </h1>
                      <Donut
                        value={reportData.info_indicator.tx_forum_seg_cvli.avg}
                        color="#075E89"
                        strokeWidth="13"
                        className="donut"
                      />
                      {!!reportData.info_indicator.tx_forum_seg_cvli
                        .avg_country && (
                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={
                              reportData.info_indicator.tx_forum_seg_cvli
                                .avg_country
                            }
                            color="#6D6E71"
                            size={50}
                            fontSize="10"
                            strokewidth={7}
                          />
                          <small>Demais Estados</small>
                        </div>
                      )}
                    </DonutContainer>
                  </Card>
                </Column>
                <Column small="3" medium="3" large="3">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>
                        {reportData.info_indicator.tx_forum_seg_patrimonio.name}
                      </h1>
                      <Donut
                        value={
                          reportData.info_indicator.tx_forum_seg_patrimonio.avg
                        }
                        color="#075E89"
                        strokeWidth="13"
                        className="donut"
                      />
                      {!!reportData.info_indicator.tx_forum_seg_patrimonio
                        .avg_country && (
                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={
                              reportData.info_indicator.tx_forum_seg_patrimonio
                                .avg_country
                            }
                            color="#6D6E71"
                            size={50}
                            fontSize="15"
                            strokewidth={7}
                          />
                          <small>Demais Estados</small>
                        </div>
                      )}
                    </DonutContainer>
                  </Card>
                </Column>
                <Column small="3" medium="3" large="3">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>
                        {reportData.info_indicator.tx_jovens_infratores.name}
                      </h1>
                      <Donut
                        value={
                          reportData.info_indicator.tx_jovens_infratores.avg
                        }
                        color="#075E89"
                        strokeWidth="13"
                        className="donut"
                      />
                      {!!reportData.info_indicator.tx_jovens_infratores
                        .avg_country && (
                        <div className="smallDonut">
                          <DonutChart
                            className="menor"
                            value={
                              reportData.info_indicator.tx_jovens_infratores
                                .avg_country
                            }
                            color="#6D6E71"
                            size={50}
                            fontSize="15"
                            strokewidth={7}
                          />
                          <small>Demais Estados</small>
                        </div>
                      )}
                    </DonutContainer>
                  </Card>
                </Column>
              </Row>
            </MainContent>
          </Main>
        </PrintDiv>
      )}
    </Container>
  );
};

export default ExecutiveReport2;
