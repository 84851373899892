import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Column } from '../../.././../../styles/components';
import Categories from '../Categories';
import { Container, Title, SubTitle } from './styles';

function ExecutiveSynthesis({ diagnostico, setBreadCrumbActive, tab, setTab }) {
  const setBreadCrumb = useCallback(
    (tabName, breadCrumbNumber) => {
      setTab(tabName);
      setBreadCrumbActive(breadCrumbNumber);
    },
    [setBreadCrumbActive, setTab],
  );

  return (
    <Container tab={tab}>
      {/* <Title style={{ marginTop: '31px' }}>Síntese Executiva</Title>
      <SubTitle style={{ marginBottom: '31px' }}>
        A síntese executiva traz uma visão de conjunto da performance de seu
        estado/município. Os indicadores selecionados foram “normalizados” em
        intervalos de 0 a 10 e, com base nesses valores, foi calculado um
        indicador sintético, representado visualmente no gráfico de radar
        apresentado abaixo.
      </SubTitle> */}
      <Title>Acesse os painéis de indicadores clicando nos ícones abaixo</Title>
      <Row style={{ marginTop: '20px' }}>
        <Categories
          categories={diagnostico.categories}
          setTab={setBreadCrumb}
        />
      </Row>
      <Row>
        <Column large="12" medium="12" small="12">
          {/* <Link to="/metodologia/indicador-sintetico" className="redirectLink">
            Saiba mais sobre os indicadores selecionados
          </Link> */}
        </Column>
      </Row>
    </Container>
  );
}

export default ExecutiveSynthesis;
