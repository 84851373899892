import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import { Form } from '@unform/web';
import BreadCrumb from './components/BreadCrumb';
import ExecutiveSynthesis from './components/ExecutiveSynthesis';
import IndicatorsPanel from './components/IndicatorsPanel';
import SolutionsAndEvidence from './components/SolutionsAndEvidence';
import DetailIndicator from './components/DetailIndicator';
import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import ExternalLayout from '../../../components/ExternalLayout';
import Loader from '../../../components/Loader';

import { Container, MainHeader, TitleContainer, MainContent } from './styles';

function DiagnosticoIndicadores() {
  const formRef = useRef(null);
  const { ibgeCode } = useParams();
  const [diagnostico, setDiagnostico] = useState(null);
  const [detailIndicator, setDetailIndicator] = useState(null);
  const [breadCrumbActive, setBreadCrumbActive] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('Criminalidade e Violência');

  const getDiagnostico = useCallback(async () => {
    try {
      const response = await api.get(`v1/diagnostico/${ibgeCode}/`);
      setDiagnostico(response.data.location);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [ibgeCode]);

  const handleSubmit = useCallback(_data => {}, []);

  useEffect(() => {
    getDiagnostico();
  }, [getDiagnostico]);

  const BreadCrumbOptions = [
    { step: 1, name: 'Síntese Executiva' },
    { step: 2, name: 'Painel de Indicadores' },
    { step: 3, name: 'Soluções e Evidências' },
  ];

  const boldString = (str, substr) => {
    const keywords = substr.split(' ').join('|');
    return str.replace(new RegExp('(' + keywords + ')', 'gi'), '<b>$1</b>');
  };

  const promiseOptions = inputValue =>
    new Promise(async resolve => {
      if (inputValue.length > 2) {
        const response = await api.get(`v1/locations/?name=${inputValue}`);

        const cityOptions = response.data.results.filter(
          i => !i.is_metropolitan_region && i.type === 'city',
        );
        const StateOptions = response.data.results.filter(
          i => !i.is_metropolitan_region && i.type !== 'city',
        );

        const slicedCityOptions = cityOptions.slice(0, 10);
        const slicedStateOptions = StateOptions.slice(0, 10);

        const groupedCities = slicedCityOptions.map(i => {
          return {
            label: `${boldString(i.text, inputValue)}`,
            value: i.ibge_code,
          };
        });

        const groupedStates = slicedStateOptions.map(i => {
          return {
            label: `${boldString(i.text, inputValue)}`,
            value: i.ibge_code,
          };
        });

        const Options = [
          {
            label: 'Estados',
            options: groupedStates,
          },
          {
            label: 'Cidades',
            options: groupedCities,
          },
        ];
        resolve(Options);
      } else {
        resolve([]);
      }
    });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ExternalLayout headerTransparent={false}>
          <Container>
            {!ibgeCode ? (
              <MainHeader>
                <div className="content">
                  <h1 className="title">Diagnóstico</h1>
                  <small className="subtitle">
                    Comece seu diagnóstico pesquisando por uma Cidade ou Estado
                  </small>
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <AsyncAutoComplete
                      placeholder="Digite o nome da Cidade ou Estado"
                      emptyMessage="Nenhuma opção encontrada"
                      loadOptions={promiseOptions}
                    />

                    <button type="submit" className="searchButton">
                      Pesquisar
                    </button>
                  </Form>
                </div>
              </MainHeader>
            ) : (
              <>
                <MainHeader>
                  <div className="content">
                    <h1 className="title">Diagnóstico</h1>
                    <small className="subtitle">
                      Comece seu diagnóstico pesquisando por uma Cidade ou
                      Estado
                    </small>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <AsyncAutoComplete
                        placeholder="Digite o nome da Cidade ou Estado"
                        emptyMessage="Nenhuma opção encontrada"
                        loadOptions={promiseOptions}
                      />

                      <button type="submit" className="searchButton">
                        Pesquisar
                      </button>
                    </Form>

                    <TitleContainer>
                      <div className="titleContent">
                        <h1>{diagnostico.name}</h1>
                      </div>
                    </TitleContainer>
                    <BreadCrumb
                      diagnostico={diagnostico}
                      options={BreadCrumbOptions}
                      setCallBack={setBreadCrumbActive}
                      active={breadCrumbActive}
                      ibgeCode={ibgeCode}
                    />
                  </div>
                </MainHeader>
                <MainContent id="mainContent">
                  <div className="content">
                    {breadCrumbActive === 1 && (
                      <ExecutiveSynthesis
                        diagnostico={diagnostico}
                        setBreadCrumbActive={setBreadCrumbActive}
                        tab={tab}
                        setTab={setTab}
                      />
                    )}
                    {breadCrumbActive === 2 && (
                      <IndicatorsPanel
                        diagnostico={diagnostico}
                        setBreadCrumbActive={setBreadCrumbActive}
                        setDetailIndicator={setDetailIndicator}
                        tab={tab}
                        setTab={setTab}
                      />
                    )}
                    {breadCrumbActive === 3 && <SolutionsAndEvidence />}
                    {breadCrumbActive === 4 && (
                      <DetailIndicator
                        detailIndicator={detailIndicator}
                        setBreadCrumbActive={setBreadCrumbActive}
                        breadCrumbActive={breadCrumbActive}
                      />
                    )}
                  </div>
                </MainContent>
              </>
            )}
          </Container>
        </ExternalLayout>
      )}
    </>
  );
}

export default DiagnosticoIndicadores;
