import React, { useCallback } from 'react';
import Bar from '../Bar';

import { Container } from './styles';

function StackedBar({ data, financial }) {
  function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  return (
    <>
      {!!data && !financial && (
        <Container>
          <Bar percentage={toFixed(data.finish, 2)} color="#5CB24B" />
          <Bar percentage={toFixed(data.start, 2)} color="#F7A643" />
          <Bar percentage={toFixed(data.no_start, 2)} color="#C1856F" />
        </Container>
      )}
      {!!data && financial && (
        <Container>
          <Bar percentage={toFixed(data.executed, 2)} color="#5CB24B" />
        </Container>
      )}
    </>
  );
}

export default StackedBar;
