/* eslint-disable */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import api from '../../../../services/api';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import getValidationErrors from '../../../../services/getValidationErrors';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import InputMask from '../../../../components/InputMask';
import InputCurrency from '../../../../components/InputCurrency';
import Modal from '../../../../components/Modal';
import ModalLoader from '../../../../components/ModalLoader';
import { Row, Column } from '../../../../styles/components';
import { Container } from './styles';

const AddOperation = ({
  show,
  handleClose,
  handleSuccess,
  operation,
  handleEditSuccess,
}) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [entitiesFinaciables, setEntitiesFinaciables] = useState([]);
  const [usersManager, setUsersManager] = useState([]);

  const schema = Yup.object().shape({
    code: Yup.string().required('Código da operação é obrigatório'),
    operation_title: Yup.string().required('Título da operação é obrigatório'),
    global_value: Yup.string().required('Valor Global é obrigatório'),
    manager: Yup.string().required('Gestor é obrigatório'),
    operation_entity: Yup.string().required(
      'Entidade Financeira é obrigatória',
    ),
    start_date: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de início válida',
    ),
    end_date: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de termino válida',
    ),
  });

  const entityFinaciablesOptions = useMemo(() => {
    return entitiesFinaciables.map(entity => ({
      value: entity.code,
      label: `${entity.name}(${entity.abbreviation})`,
    }));
  }, [entitiesFinaciables]);

  const UserManagerOptions = useMemo(() => {
    return usersManager.map(user => ({
      value: user.id,
      label: `${user.full_name}(${user.username})`,
    }));
  }, [usersManager]);

  const getEntityFinaciableOptions = entity => {
    return {
      value: entity.code,
      label: `${entity.name}(${entity.abbreviation})`,
    };
  };

  const getUserManagerOptions = user => {
    return {
      value: user.id,
      label: `${user.full_name}(${user.username})`,
    };
  };

  useEffect(() => {
    api.get('/credits/api/v1/financing-entity/').then(response => {
      setEntitiesFinaciables(response.data.results);
    });
    api.get('/accounts/users/?manager=true').then(response => {
      setUsersManager(response.data.results);
    });
  }, []);

  useEffect(() => {
    if (!!operation && Object.keys(operation).length) {
      const finEntity = entitiesFinaciables.filter(
        fin => fin.code === operation.financing_entity,
      )[0];
      !!finEntity &&
        formRef.current?.setFieldValue(
          'operation_entity',
          getEntityFinaciableOptions(finEntity),
        );
    }
  }, [entitiesFinaciables, operation]);

  useEffect(() => {
    if (!!operation && Object.keys(operation).length) {
      const manager = usersManager.filter(
        man => man.id === operation.manager,
      )[0];
      !!manager &&
        formRef.current?.setFieldValue(
          'manager',
          getUserManagerOptions(manager),
        );
    }
  }, [usersManager, operation]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const splitedStartDate = data.start_date.split('/');
        const splitedEndDate = data.end_date.split('/');

        await api.post('/credits/api/v1/register/', {
          ...data,
          start_date: `${splitedStartDate[2]}-${splitedStartDate[1]}-${splitedStartDate[0]}`,
          end_date: `${splitedEndDate[2]}-${splitedEndDate[1]}-${splitedEndDate[0]}`,
          standard_docs: {},
        });

        handleSuccess();
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema],
  );

  const handleEdit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const splitedStartDate = data.start_date.split('/');
        const splitedEndDate = data.end_date.split('/');

        await api.patch(`/credits/api/v1/opcredit-manager/${operation.code}/`, {
          ...data,
          start_date: `${splitedStartDate[2]}-${splitedStartDate[1]}-${splitedStartDate[0]}`,
          end_date: `${splitedEndDate[2]}-${splitedEndDate[1]}-${splitedEndDate[0]}`,
        });

        handleEditSuccess();
        setLoading(false);
        setsuccess(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema, handleEditSuccess, operation],
  );

  return (
    <Container>
      <Modal
        show={show}
        success={success}
        handleClose={handleClose}
        title={
          !!operation
            ? 'Editar Operação de Crédito'
            : 'Cadastrar nova Operação de crédito'
        }
        successText={
          !!operation
            ? 'Operação de Crédito editada com sucesso'
            : 'Operação de crédito cadastrada com sucesso'
        }
      >
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <Form
              ref={formRef}
              onSubmit={!!operation ? handleEdit : handleSubmit}
            >
              <Row>
                <Column small="12" medium="12" large="4">
                  <Input
                    name="code"
                    label="Código da operação"
                    type="number"
                    className="codigo_operacao"
                    placeholder="Digite o código da operação"
                    defaultValue={!!operation ? operation.code : ''}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="8">
                  <Input
                    name="operation_title"
                    label="Título da operação"
                    className="titulo_operacao"
                    placeholder="EX: Governo do estado de Goiás"
                    defaultValue={!!operation ? operation.operation_title : ''}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <Select
                    name="manager"
                    label="Gestor"
                    placeholder="Selecione o gestor"
                    options={UserManagerOptions}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <Select
                    name="operation_entity"
                    label="Entidade Financeira"
                    placeholder="Selecione a entidade financeira"
                    options={entityFinaciablesOptions}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="4">
                  <InputMask
                    name="start_date"
                    label="Data de Início"
                    mask="99/99/9999"
                    schema={schema}
                    maskChar={null}
                    defaultValue={
                      !!operation
                        ? `${operation.start_date.split('-')[2]}/${
                            operation.start_date.split('-')[1]
                          }/${operation.start_date.split('-')[0]}`
                        : ''
                    }
                    placeholder="Digite a data de início"
                  />
                </Column>
                <Column small="12" medium="12" large="4">
                  <InputMask
                    name="end_date"
                    label="Data de Termino"
                    mask="99/99/9999"
                    schema={schema}
                    maskChar={null}
                    defaultValue={
                      !!operation
                        ? `${operation.end_date.split('-')[2]}/${
                            operation.end_date.split('-')[1]
                          }/${operation.end_date.split('-')[0]}`
                        : ''
                    }
                    placeholder="Digite a data de termino"
                  />
                </Column>
                <Column small="12" medium="12" large="4">
                  <InputCurrency
                    name="global_value"
                    label="Valor Global"
                    schema={schema}
                    placeholder="Digite o valor global"
                    defaultValue={
                      !!operation ? operation.global_value.value : ''
                    }
                  />
                </Column>
              </Row>
              <button type="submit">{!!operation ? 'Editar' : 'Salvar'}</button>
            </Form>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default AddOperation;
