import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #10385c;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 95px;

    & > div {
      white-space: inherit;
    }
  }
`;

export const UpdateButton = styled.button`
  width: 23px;
  height: 23px;

  background: #5eb245;
  border-radius: 5px;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;
