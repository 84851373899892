import React from 'react';
import { useAuth } from '../../hooks/auth';
import SiteHeader from '../../components/SiteHeader';
import Footer from '../../components/Footer';
import LoginModal from '../../components/LoginModal';
import ForgotModal from '../../components/ForgotModal';
import RegisterModal from '../../components/RegisterModal';
import { Container, Main } from './styles';

function ExternalLayout({ headerTransparent, children }) {
  const {
    showLoginModal,
    changeLoginModal,
    showForgotModal,
    changeForgotModal,
    showRegistrationModal,
    changeRegistrationModal,
  } = useAuth();

  return (
    <Container>
      <SiteHeader transparent={headerTransparent} />
      <Main>{children}</Main>
      <Footer />
      {showLoginModal && (
        <LoginModal
          show={showLoginModal}
          handleClose={() => changeLoginModal(!showLoginModal)}
        />
      )}
      {showForgotModal && (
        <ForgotModal
          show={showForgotModal}
          handleClose={() => changeForgotModal(!showForgotModal)}
        />
      )}
      {showRegistrationModal && (
        <RegisterModal
          show={showRegistrationModal}
          handleClose={() => changeRegistrationModal(!showRegistrationModal)}
        />
      )}
    </Container>
  );
}

export default ExternalLayout;
