import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: 1;

  @media screen and (max-width: 994px) {
    display: block;
    .left {
      margin-left: auto;
      margin-right: auto;
    }
    .right {
      margin-left: auto;
      margin-right: auto;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 42px;
    color: #fff !important;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .text {
    font-size: 19px;
    color: #fff;
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 25px;
    }
  }
  .left {
    width: 40vw;
  }
  .right {
    display: flex;
    flex-direction: column;
    width: 60vw;
  }
  .transparent-box {
    background-color: transparent;
    border: 1px solid #ffffff !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #fff;
    padding: 6px 6px 0px 6px;
    margin-top: 20px;
    display: flex;
  }
  .programa-transparent-box-text {
    text-align: left;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  .bold {
    font-weight: 700;
    margin-top: 25px;
  }
  .nome-wrapper {
    width: 30%;
  }
  .email-wrapper {
    margin-left: 30px;
    width: 70%;
  }
  .form-wrapper {
    display: flex;
  }
  @media screen and (max-width: 994px) {
    .form-wrapper {
      flex-direction: column;
    }
    .row {
      flex-direction: column !important;
    }
    .nome-wrapper {
      width: 100% !important;
    }
    .email-wrapper {
      width: 100%;
      margin-left: 0px !important;
      margin-top: 10px;
    }
    .cidade-wrapper {
      width: 100% !important;
      margin-left: 0px !important;
    }
    .estado-wrapper {
      width: 100% !important;
      margin-top: 10px;
    }
  }
  Form {
    width: 100% !important;
  }
  .row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: inherit;
  }
  .react-select__placeholder {
    color: var(--gray2);
    font-size: 15px;
  }
  .react-select__control {
    height: 45px;
  }
  .estado-wrapper {
    width: 50%;
    margin-top: 20px;
  }
  .cidade-wrapper {
    width: 50%;
    margin-left: 30px;
    margin-top: 20px;
  }
  .btn {
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
`;
