import React, { useState, useEffect, useCallback } from 'react';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import DashBoardLayout from '../../components/DashboardLayout';
import Algemas from '../../assets/algemas.svg';
import Hands from '../../assets/hands.svg';
import Government from '../../assets/government.svg';
import { ReactComponent as BrasilFlag } from '../../assets/brazil-flag.svg';
import { ReactComponent as Like } from '../../assets/like.svg';
import { ReactComponent as Mapa } from '../../assets/mapa_goias.svg';

import CapitalEmpty from '../../assets/capital-empty.png';
import CapitalIgual from '../../assets/capital-igual.png';
import CapitalMaior from '../../assets/capital-maior.png';
import CapitalMenor from '../../assets/capital-menor.png';

import EstadoEmpty from '../../assets/estado-empty.png';
import EstadoIgual from '../../assets/estado-igual.png';
import EstadoMaior from '../../assets/estado-maior.png';
import EstadoMenor from '../../assets/estado-menor.png';

import MetropEmpty from '../../assets/metrop-empty.png';
import MetropIgual from '../../assets/metrop-igual.png';
import MetropMaior from '../../assets/metrop-maior.png';
import MetropMenor from '../../assets/metrop-menor.png';

import Igual from '../../assets/igual.png';
import Empty from '../../assets/empty.png';
import FlagEmpty from '../../assets/flag-empty.png';

import { FaSearch } from 'react-icons/fa';
import {
  Container,
  BackButton,
  TabButton,
  InfoTitleContainer,
  TitleButton,
  CardsContainer,
  ImpactCard,
  IconContainer,
  LengendContainer,
  Info,
} from './styles';
import { Column, Row } from '../../styles/components';

const OperationImpactIndicators = () => {
  const { goBack, push } = useHistory();
  const { operationId } = useParams();
  const [impactsData, setImpactsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('1');

  useEffect(() => {
    api
      .get(`credits/api/v1/monitoring-impact/${operationId}`)
      .then(response => {
        setImpactsData(response.data);
        console.log(response.data);
        setLoading(false);
      });
  }, [operationId]);

  const handleRedirect = useCallback(data => {
    window.open(
      `/diagnostico-indicadores/${data.ibge_code}/${data.indicator_id}/`,
      '_blank',
    );
  }, []);

  const getColor = useCallback(value => {
    const colors = {
      '-1': '#ff534a',
      0: '#bfbfbf',
      1: '#5fb346',
      2: '#bfbfbf',
    };
    return colors[value];
  }, []);

  return (
    <Container tab={tab}>
      <DashBoardLayout title="Monitoramento dos Impactos">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Column large="12" medium="12" small="12">
                <>
                  <BackButton onClick={() => goBack()}>Voltar</BackButton>
                  <InfoTitleContainer>
                    <h1>{impactsData.location.name}</h1>
                    {/* <div className="ticketsContainer">
                      <span className="ranking">
                        Ranking: {`${impactsData.location.ranking}°`}
                      </span>
                      <span className="nota">
                        Nota: {impactsData.location.score}
                      </span>
                    </div> */}
                  </InfoTitleContainer>
                </>
              </Column>
            </Row>
            <TitleButton>
              <small>Operação de Crédito</small>
              <h1>
                {`(${impactsData.operation_entity.abbreviation} ${impactsData.code}) `}
                {impactsData.operation_title}
              </h1>
            </TitleButton>
            <Row className="TextContainer">
              <Column large="12" medium="12" small="12">
                <h1>Painel de Indicadores</h1>
                <p>
                  Nesta seção, é possível visualizar a trajetória recente e
                  tendências extrapolativas para os indicadores de impacto
                  referentes aos projetos contratados pela operação de crédito
                </p>
                <h2>Saiba mais sobre os indicadores selecionados</h2>
                <small
                  onClick={() => push('/management/metodologia-indicador')}
                >
                  Saiba mais sobre os indicadores selecionados
                </small>
              </Column>
            </Row>
            <Row className="TabsContainer">
              <Column large="4" medium="4" small="4">
                <TabButton color="#3aa9ee" onClick={() => setTab('1')}>
                  <img src={Algemas} alt="algemas" />
                  <span>Criminalidade e Violência</span>
                  <div>
                    <h2>Ver Painel de Indicadores</h2>
                  </div>
                </TabButton>
              </Column>
              <Column large="4" medium="4" small="4">
                <TabButton color="#287bac" onClick={() => setTab('2')}>
                  <img src={Hands} alt="Hands" />
                  <span>Fatores de Risco</span>
                  <div>
                    <h2>Ver Painel de Indicadores</h2>
                  </div>
                </TabButton>
              </Column>
              <Column large="4" medium="4" small="4">
                <TabButton color="#14486b" onClick={() => setTab('3')}>
                  <img src={Government} alt="Government" />
                  <span>Desenpenho Institucional</span>
                  <div>
                    <h2>Ver Painel de Indicadores</h2>
                  </div>
                </TabButton>
              </Column>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Column large="12" medium="12" small="12">
                {tab === '1' && (
                  <CardsContainer>
                    <h1>Criminalidade e Violência</h1>
                    {!impactsData.desired_impacts.criminal.length && (
                      <p className="emptyMessage">Nenhum Impacto Vinculado</p>
                    )}
                    <Row>
                      {impactsData.desired_impacts.criminal.map(item => (
                        <Column large="4" medium="6" small="12">
                          <ImpactCard>
                            <div className="headContent">
                              <div className="impactContainer">
                                <h1>{item.impact_indicator.impact_name}</h1>
                                <small>
                                  INDICADOR:
                                  {item.impact_indicator.indicator_name}
                                </small>
                                <small>{item.impact_indicator.source}</small>
                                <small>{item.impact_indicator.period}</small>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    handleRedirect(item.impact_indicator)
                                  }
                                  type="button"
                                >
                                  <FaSearch size={15} color="#FFF" />
                                </button>
                              </div>
                            </div>
                            <div className="IconContent">
                              <IconContainer
                                color={getColor(
                                  item.impact_indicator.evolution_cmp,
                                )}
                                rotate={
                                  !!(item.impact_indicator.evolution_cmp === 1)
                                    ? true
                                    : false
                                }
                              >
                                {item.impact_indicator.evolution_cmp === 0 && (
                                  <img src={Igual} alt="" />
                                )}
                                {item.impact_indicator.evolution_cmp === 2 && (
                                  <img src={Empty} alt="" />
                                )}
                                {item.impact_indicator.evolution_cmp !== 2 &&
                                  item.impact_indicator.evolution_cmp !== 0 && (
                                    <Like />
                                  )}
                              </IconContainer>

                              {(impactsData.location.is_state ||
                                (impactsData.location.is_capital &&
                                  !impactsData.location.is_metropolitan_city &&
                                  impactsData.location.is_county &&
                                  impactsData.location.population >
                                    100000)) && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.region_cmp,
                                  )}
                                >
                                  {item.impact_indicator.region_cmp === -1 && (
                                    <img src={CapitalMenor} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 0 && (
                                    <img src={CapitalIgual} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 1 && (
                                    <img src={CapitalMaior} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 2 && (
                                    <img src={CapitalEmpty} alt="" />
                                  )}
                                </IconContainer>
                              )}

                              <IconContainer
                                color={getColor(
                                  item.impact_indicator.country_cmp,
                                )}
                              >
                                {item.impact_indicator.country_cmp !== 2 && (
                                  <BrasilFlag />
                                )}
                                {item.impact_indicator.country_cmp === 2 && (
                                  <img src={FlagEmpty} alt="" />
                                )}
                              </IconContainer>

                              {/* é Estado */}
                              {!impactsData.location.is_state && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.state_cmp,
                                  )}
                                >
                                  {item.impact_indicator.state_cmp === -1 && (
                                    <img src={EstadoMenor} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 0 && (
                                    <img src={EstadoIgual} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 1 && (
                                    <img src={EstadoMaior} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 2 && (
                                    <img src={EstadoEmpty} alt="" />
                                  )}
                                </IconContainer>
                              )}

                              {((impactsData.location.is_capital &&
                                impactsData.location.is_metropolitan_city) ||
                                (impactsData.location.is_county &&
                                  impactsData.location
                                    .is_metropolitan_city)) && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.metropolis_cmp,
                                  )}
                                >
                                  {item.impact_indicator.metropolis_cmp ===
                                    -1 && <img src={MetropMenor} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    0 && <img src={MetropIgual} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    1 && <img src={MetropMaior} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    2 && <img src={MetropEmpty} alt="" />}
                                </IconContainer>
                              )}
                            </div>
                          </ImpactCard>
                        </Column>
                      ))}
                    </Row>
                  </CardsContainer>
                )}
                {tab === '2' && (
                  <CardsContainer>
                    <h1>Fatores de Risco</h1>
                    {!impactsData.desired_impacts.risk_factor.length && (
                      <p className="emptyMessage">Nenhum Impacto Vinculado</p>
                    )}
                    <Row>
                      {impactsData.desired_impacts.risk_factor.map(item => (
                        <Column large="4" medium="6" small="12">
                          <ImpactCard>
                            <div className="headContent">
                              <div className="impactContainer">
                                <h1>{item.impact_indicator.impact_name}</h1>
                                <small>
                                  INDICADOR:
                                  {item.impact_indicator.indicator_name}
                                </small>
                                <small>{item.impact_indicator.source}</small>
                                <small>{item.impact_indicator.period}</small>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    handleRedirect(item.impact_indicator)
                                  }
                                  type="button"
                                >
                                  <FaSearch size={15} color="#FFF" />
                                </button>
                              </div>
                            </div>
                            <div className="IconContent">
                              <IconContainer
                                color={getColor(
                                  item.impact_indicator.evolution_cmp,
                                )}
                                rotate={
                                  !!(item.impact_indicator.evolution_cmp === 1)
                                    ? true
                                    : false
                                }
                              >
                                {item.impact_indicator.evolution_cmp === 0 && (
                                  <img src={Igual} alt="" />
                                )}
                                {item.impact_indicator.evolution_cmp === 2 && (
                                  <img src={Empty} alt="" />
                                )}
                                {item.impact_indicator.evolution_cmp !== 2 &&
                                  item.impact_indicator.evolution_cmp !== 0 && (
                                    <Like />
                                  )}
                              </IconContainer>

                              {(impactsData.location.is_state ||
                                (impactsData.location.is_capital &&
                                  !impactsData.location.is_metropolitan_city &&
                                  impactsData.location.is_county &&
                                  impactsData.location.population >
                                    100000)) && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.region_cmp,
                                  )}
                                >
                                  {item.impact_indicator.region_cmp === -1 && (
                                    <img src={CapitalMenor} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 0 && (
                                    <img src={CapitalIgual} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 1 && (
                                    <img src={CapitalMaior} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 2 && (
                                    <img src={CapitalEmpty} alt="" />
                                  )}
                                </IconContainer>
                              )}

                              <IconContainer
                                color={getColor(
                                  item.impact_indicator.country_cmp,
                                )}
                              >
                                {item.impact_indicator.country_cmp !== 2 && (
                                  <BrasilFlag />
                                )}
                                {item.impact_indicator.country_cmp === 2 && (
                                  <img src={FlagEmpty} alt="" />
                                )}
                              </IconContainer>

                              {/* é Estado */}
                              {!impactsData.location.is_state && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.state_cmp,
                                  )}
                                >
                                  {item.impact_indicator.state_cmp === -1 && (
                                    <img src={EstadoMenor} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 0 && (
                                    <img src={EstadoIgual} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 1 && (
                                    <img src={EstadoMaior} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 2 && (
                                    <img src={EstadoEmpty} alt="" />
                                  )}
                                </IconContainer>
                              )}

                              {((impactsData.location.is_capital &&
                                impactsData.location.is_metropolitan_city) ||
                                (impactsData.location.is_county &&
                                  impactsData.location
                                    .is_metropolitan_city)) && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.metropolis_cmp,
                                  )}
                                >
                                  {item.impact_indicator.metropolis_cmp ===
                                    -1 && <img src={MetropMenor} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    0 && <img src={MetropIgual} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    1 && <img src={MetropMaior} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    2 && <img src={MetropEmpty} alt="" />}
                                </IconContainer>
                              )}
                            </div>
                          </ImpactCard>
                        </Column>
                      ))}
                    </Row>
                  </CardsContainer>
                )}
                {tab === '3' && (
                  <CardsContainer>
                    <h1>Desenpenho Institucional</h1>
                    {!impactsData.desired_impacts.performance.length && (
                      <p className="emptyMessage">Nenhum Impacto Vinculado</p>
                    )}
                    <Row>
                      {impactsData.desired_impacts.performance.map(item => (
                        <Column large="4" medium="6" small="12">
                          <ImpactCard>
                            <div className="headContent">
                              <div className="impactContainer">
                                <h1>{item.impact_indicator.impact_name}</h1>
                                <small>
                                  INDICADOR:
                                  {item.impact_indicator.indicator_name}
                                </small>
                                <small>{item.impact_indicator.source}</small>
                                <small>{item.impact_indicator.period}</small>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    handleRedirect(item.impact_indicator)
                                  }
                                  type="button"
                                >
                                  <FaSearch size={15} color="#FFF" />
                                </button>
                              </div>
                            </div>
                            <div className="IconContent">
                              <IconContainer
                                color={getColor(
                                  item.impact_indicator.evolution_cmp,
                                )}
                                rotate={
                                  !!(item.impact_indicator.evolution_cmp === 1)
                                    ? true
                                    : false
                                }
                              >
                                {item.impact_indicator.evolution_cmp === 0 && (
                                  <img src={Igual} alt="" />
                                )}
                                {item.impact_indicator.evolution_cmp === 2 && (
                                  <img src={Empty} alt="" />
                                )}
                                {item.impact_indicator.evolution_cmp !== 2 &&
                                  item.impact_indicator.evolution_cmp !== 0 && (
                                    <Like />
                                  )}
                              </IconContainer>

                              {(impactsData.location.is_state ||
                                (impactsData.location.is_capital &&
                                  !impactsData.location.is_metropolitan_city &&
                                  impactsData.location.is_county &&
                                  impactsData.location.population >
                                    100000)) && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.region_cmp,
                                  )}
                                >
                                  {item.impact_indicator.region_cmp === -1 && (
                                    <img src={CapitalMenor} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 0 && (
                                    <img src={CapitalIgual} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 1 && (
                                    <img src={CapitalMaior} alt="" />
                                  )}
                                  {item.impact_indicator.region_cmp === 2 && (
                                    <img src={CapitalEmpty} alt="" />
                                  )}
                                </IconContainer>
                              )}

                              <IconContainer
                                color={getColor(
                                  item.impact_indicator.country_cmp,
                                )}
                              >
                                {item.impact_indicator.country_cmp !== 2 && (
                                  <BrasilFlag />
                                )}
                                {item.impact_indicator.country_cmp === 2 && (
                                  <img src={FlagEmpty} alt="" />
                                )}
                              </IconContainer>

                              {/* é Estado */}
                              {!impactsData.location.is_state && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.state_cmp,
                                  )}
                                >
                                  {item.impact_indicator.state_cmp === -1 && (
                                    <img src={EstadoMenor} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 0 && (
                                    <img src={EstadoIgual} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 1 && (
                                    <img src={EstadoMaior} alt="" />
                                  )}
                                  {item.impact_indicator.state_cmp === 2 && (
                                    <img src={EstadoEmpty} alt="" />
                                  )}
                                </IconContainer>
                              )}

                              {((impactsData.location.is_capital &&
                                impactsData.location.is_metropolitan_city) ||
                                (impactsData.location.is_county &&
                                  impactsData.location
                                    .is_metropolitan_city)) && (
                                <IconContainer
                                  color={getColor(
                                    item.impact_indicator.metropolis_cmp,
                                  )}
                                >
                                  {item.impact_indicator.metropolis_cmp ===
                                    -1 && <img src={MetropMenor} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    0 && <img src={MetropIgual} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    1 && <img src={MetropMaior} alt="" />}
                                  {item.impact_indicator.metropolis_cmp ===
                                    2 && <img src={MetropEmpty} alt="" />}
                                </IconContainer>
                              )}
                            </div>
                          </ImpactCard>
                        </Column>
                      ))}
                    </Row>
                  </CardsContainer>
                )}
              </Column>
            </Row>
            <Row>
              <Column large="12" medium="12" small="12">
                <LengendContainer>
                  <h1>Legenda:</h1>
                  <Row>
                    <Column large="3" medium="3" small="3">
                      <Info>
                        <h2>Evolução</h2>
                        <div className="legendContent">
                          <IconContainer color="#5EB245" rotate={true} legend>
                            <Like />
                          </IconContainer>
                          <span>Melhora no período</span>
                        </div>
                        <div className="legendContent">
                          <IconContainer color="#FF534A" legend>
                            <Like />
                          </IconContainer>
                          <span>Piora no período</span>
                        </div>
                        <div className="legendContent">
                          <IconContainer color="#AAB9BF" legend>
                            <img src={Igual} alt="" />
                          </IconContainer>
                          <span>Estável no período</span>
                        </div>
                        <div className="legendContent">
                          <IconContainer color="#AAB9BF" legend>
                            <img src={Empty} alt="" />
                          </IconContainer>
                          <span>Não comparável</span>
                        </div>
                      </Info>
                    </Column>
                    
                    {!impactsData.location.is_state && (
                      <Column large="3" medium="3" small="3">
                        <Info>
                          <h2>Comparação com o Estado</h2>
                          <div className="legendContent">
                            <IconContainer color="#5EB245" legend>
                              <img src={EstadoMaior} alt="" />
                            </IconContainer>
                            <span>Melhor do que o estado no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#FF534A" legend>
                              <img src={EstadoMenor} alt="" />
                            </IconContainer>
                            <span>Pior que o estado no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#AAB9BF" legend>
                              <img src={EstadoIgual} alt="" />
                            </IconContainer>
                            <span>Igual ao estado no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#AAB9BF" legend>
                              <img src={EstadoEmpty} alt="" />
                            </IconContainer>
                            <span>Não Comparável</span>
                          </div>
                        </Info>
                      </Column>
                    )}

                    <Column large="3" medium="3" small="3">
                      <Info>
                        <h2>Comparação com o Brasil</h2>
                        <div className="legendContent">
                          <IconContainer color="#5EB245" legend>
                            <BrasilFlag />
                          </IconContainer>
                          <span>Melhor que a país no último ano</span>
                        </div>
                        <div className="legendContent">
                          <IconContainer color="#FF534A" legend>
                            <BrasilFlag />
                          </IconContainer>
                          <span>Pior que o país no último ano</span>
                        </div>
                        <div className="legendContent">
                          <IconContainer color="#AAB9BF" legend>
                            <BrasilFlag />
                          </IconContainer>
                          <span>Igual ao país no último ano</span>
                        </div>
                        <div className="legendContent">
                          <IconContainer color="#AAB9BF" legend>
                            <img src={FlagEmpty} alt="" />
                          </IconContainer>
                          <span>Não comparável</span>
                        </div>
                      </Info>
                    </Column>

                    {(impactsData.location.is_state ||
                      (impactsData.location.is_capital &&
                        !impactsData.location.is_metropolitan_city &&
                        impactsData.location.is_county &&
                        impactsData.location.population > 100000)) && (
                      <Column large="3" medium="3" small="3">
                        <Info>
                          <h2>Comparação com a média regional</h2>
                          <div className="legendContent">
                            <IconContainer color="#5EB245" legend>
                              <img src={CapitalMaior} alt="" />
                            </IconContainer>
                            <span>Melhor que a região no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#FF534A" legend>
                              <img src={CapitalMenor} alt="" />
                            </IconContainer>
                            <span>Pior que o região no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#AAB9BF" legend>
                              <img src={CapitalIgual} alt="" />
                            </IconContainer>
                            <span>Igual ao região no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#AAB9BF" legend>
                              <img src={CapitalEmpty} alt="" />
                            </IconContainer>
                            <span>Não compárável</span>
                          </div>
                        </Info>
                      </Column>
                    )}

                    {((impactsData.location.is_capital &&
                      impactsData.location.is_metropolitan_city) ||
                      (impactsData.location.is_county &&
                        impactsData.location.is_metropolitan_city)) && (
                      <Column large="3" medium="3" small="3">
                        <Info>
                          <h2>Comparação com a região metropolitana</h2>
                          <div className="legendContent">
                            <IconContainer color="#5EB245" legend>
                              <img src={MetropMaior} alt="" />
                            </IconContainer>
                            <span>Melhor que a região no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#FF534A" legend>
                              <img src={MetropMenor} alt="" />
                            </IconContainer>
                            <span>Pior que o região no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#AAB9BF" legend>
                              <img src={MetropIgual} alt="" />
                            </IconContainer>
                            <span>Igual ao região no último ano</span>
                          </div>
                          <div className="legendContent">
                            <IconContainer color="#AAB9BF" legend>
                              <img src={MetropEmpty} alt="" />
                            </IconContainer>
                            <span>Não compárável</span>
                          </div>
                        </Info>
                      </Column>
                    )}
                  </Row>
                </LengendContainer>
              </Column>
            </Row>
          </>
        )}
      </DashBoardLayout>
    </Container>
  );
};

export default OperationImpactIndicators;
