import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import ConfirmationModal from '../../components/ConfirmationModal';
import ErrorModal from '../../components/ErrorModal';
import { useHistory } from 'react-router-dom';
import { useLoader } from '../../hooks/loader';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import DashboardLayout from '../../components/DashboardLayout';
import {
  FaSearch,
  FaTrash,
  FaTrashRestore,
  FaCheck,
  FaWindowClose,
} from 'react-icons/fa';
import { Row, Column } from '../../styles/components';
import {
  Container,
  SearchButton,
  TotalizerCard,
  DeleteButton,
  ButtonsContainer,
  TableContainer,
} from './styles';
import PercentageBar from '../../components/PercentageBar';
import TimePercentageBar from '../../components/TimePercentageBar';
import ExecutiveReportModal from './Modal';

function ListCreditOperation() {
  const [countOperation, setCountOperation] = useState({});
  const [operations, setOperations] = useState([]);
  const [deletedOperations, setDeletedOperations] = useState([]);
  const [doneOperations, setDoneOperations] = useState([]);
  const [operationToConclude, setOperationToConclude] = useState();
  const [operationToDelete, setOperationToDelete] = useState();
  const [operationToUndelete, setOperationToUndelete] = useState();
  const [operationToDesconclude, setOperationToDesconclude] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showDesconcludeModal, setShowDesconcludeModal] = useState(false);
  const [showConcludeModal, setShowConcludeModal] = useState(false);
  const [showDeleteOperation, setShowDeleteOperation] = useState(false);
  const [showUndeleteOperation, setShowUndeleteOperation] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { push } = useHistory();
  const { activeLoader } = useLoader();

  const getCountOperations = useCallback(async () => {
    try {
      const response = await api.get('/credits/api/v1/opcredit-manager/count');
      setCountOperation(response.data);
      activeLoader(false);
    } catch (err) {
      activeLoader(false);
      console.error(err);
    }
  }, [activeLoader]);

  const getOperations = useCallback(async () => {
    try {
      const response = await api.get('/credits/api/v1/opcredit-manager/');

      const deleted = response.data.results.filter(
        op => op.is_delete && op.status !== 'done',
      );

      const done = response.data.results.filter(
        op => op.status === 'done' && !op.is_delete,
      );

      const operations = response.data.results.filter(
        op => op.status !== 'done' && !op.is_delete,
      );

      setOperations(operations);
      setDeletedOperations(deleted);
      setDoneOperations(done);
      console.log('operations', operations, 'done', done, 'deleted', deleted);
      activeLoader(false);
    } catch (err) {
      activeLoader(false);
      console.error(err);
      setError(true);
      setErrorMessage('Erro');
    }
  }, [activeLoader]);

  const CreateTableData = useCallback(tableData => {
    return tableData.map(operation => ({
      code: operation.code,
      execution: operation.execution,
      credit_operation: `(${operation.financing_entity} ${operation.code}) ${operation.operation_title}`,
      start_date: `${format(parseISO(operation.start_date), 'dd/MM/yyyy')}`,
      end_date: `${format(parseISO(operation.end_date), 'dd/MM/yyyy')}`,
      global_value: `R$ ${operation.global_value.repre}`,
      status: operation.status,
    }));
  }, []);

  const handleConcludeOperation = useCallback(
    async operationId => {
      try {
        activeLoader(true);
        const apiUrl = `/credits/api/v1/opcredit-manager/${operationId}/status/`;
        await api.post(apiUrl, {
          status: 'done',
        });
        getOperations();
        activeLoader(false);
      } catch (err) {
        activeLoader(false);
        const { ...error } = err;
        console.log('error', error);
        setError(true);
        setErrorMessage('algum Erro aconteceu.');
      }
    },
    [getOperations, activeLoader],
  );

  const handleDesConcludeOperation = useCallback(
    async operationId => {
      try {
        activeLoader(true);
        const apiUrl = `/credits/api/v1/opcredit-manager/${operationId}/status/`;
        await api.post(apiUrl, {
          status: 'structuring',
        });
        getOperations();
        activeLoader(false);
      } catch (err) {
        activeLoader(false);
        const { ...error } = err;
        console.log('error', error);
        setError(true);
        setErrorMessage('algum Erro aconteceu.');
      }
    },
    [getOperations, activeLoader],
  );

  const handleOpenConcludeModal = useCallback(operationId => {
    setOperationToConclude(operationId);
    setShowConcludeModal(true);
  }, []);

  const handleOpenDeleteModal = useCallback(operationId => {
    setOperationToDelete(operationId);
    setShowDeleteOperation(true);
  }, []);

  const handleOpenUndeleteModal = useCallback(operationId => {
    setOperationToUndelete(operationId);
    setShowUndeleteOperation(true);
  }, []);

  const handleOpenDesconcludeModal = useCallback(operationId => {
    setOperationToDesconclude(operationId);
    setShowDesconcludeModal(true);
  }, []);

  const handleRedirect = useCallback(
    code => {
      push(`/management/operacao-credito/${code}`);
    },
    [push],
  );

  const OperationActions = useCallback(
    row => {
      return (
        <ButtonsContainer>
          <SearchButton onClick={() => handleRedirect(row.code)}>
            <FaSearch
              title="Ver Operação de crédito"
              size={18}
              color="#ffffff"
            />
          </SearchButton>
          <DeleteButton
            title="Concluir Operação de crédito"
            color="#5eb245"
            onClick={() => handleOpenConcludeModal(row.code)}
          >
            <FaCheck size={18} color="#ffffff" />
          </DeleteButton>
          <DeleteButton
            title="Inativar operação de crédito"
            onClick={() => handleOpenDeleteModal(row.code)}
          >
            <FaTrash size={18} color="#ffffff" />
          </DeleteButton>
        </ButtonsContainer>
      );
    },
    [handleOpenDeleteModal, handleOpenConcludeModal, handleRedirect],
  );

  const DeletedOperationActions = useCallback(
    row => {
      return (
        <ButtonsContainer>
          <SearchButton
            title="Ver Operação de crédito"
            onClick={() => handleRedirect(row.code)}
          >
            <FaSearch size={18} color="#ffffff" />
          </SearchButton>
          <DeleteButton
            title="Reativar Operação de crédito"
            color="#5eb245"
            onClick={() => handleOpenUndeleteModal(row.code)}
          >
            <FaTrashRestore size={18} color="#ffffff" />
          </DeleteButton>
        </ButtonsContainer>
      );
    },
    [handleOpenUndeleteModal, handleRedirect],
  );

  const OperationDoneActions = useCallback(
    row => {
      return (
        <ButtonsContainer>
          <SearchButton onClick={() => handleRedirect(row.code)}>
            <FaSearch size={18} color="#ffffff" />
          </SearchButton>
          <DeleteButton
            title="Reativar Operação de crédito"
            color="#5eb245"
            onClick={() => handleOpenDesconcludeModal(row.code)}
          >
            <FaWindowClose size={18} color="#ffffff" />
          </DeleteButton>
        </ButtonsContainer>
      );
    },
    [handleOpenDeleteModal, handleRedirect],
  );

  const CreateTableColumns = useCallback(tableActions => {
    return [
      {
        name: '',
        selector: 'credit_operation',
        minWidth: '113px',
      },
      {
        name: 'Data Inicial',
        selector: 'start_date',
        maxWidth: '113px',
      },
      {
        name: 'Data Final',
        selector: 'end_date',
        maxWidth: '113px',
      },
      {
        name: 'Valor',
        selector: 'global_value',
        minWidth: '250px',
        cell: row => (
          <div style={{ width: '100%' }}>
            <PercentageBar
              percentage={row.execution.physics}
              title="Execução Fisica"
              color="yellow"
              dataTip='Fórmula de Cálculo: O percentual aqui apresentado se baseia no estágio de execução das entregas vinculadas à cada operação de crédito. Para tanto, dividem-se os 100% (referentes à execução total do projeto) pelo número de entregas cadastradas, dando origem à intervalos iguais, correspondentes ao total de entregas vinculadas à operação. O percentual de execução de cada um desses intervalos é definido pelo grau de execução de cada entrega individualmente. Desse modo, se, por exemplo, uma operação tem dez entregas (100/10 = 10), com oito delas "zeradas" (0%), uma com execução de 100% (100% de 10 = 10) e outra com execução de 50% (50% de 10 = 5), então o status geral da operação será de 15% (0 + 10 + 5 = 15).'
            />
            <PercentageBar
              percentage={row.execution.finance}
              title="Execução Financeira"
              color="green"
              dataTip="Fórmula de Cálculo: Montante financeiro pago até o momento / Montante financeiro previsto (total da operação) *100."
            />
            <TimePercentageBar
              percentage={row.execution.time_month}
              color="blue"
              title="Tempo"
              dataTip="Fórmula de Cálculo: Tempo decorrido desde o início do projeto em meses / Duração planejada em meses *100."
            />
          </div>
        ),
      },
      {
        name: 'Ações',
        selector: 'code',
        width: '12%',
        cell: row => tableActions(row),
      },
    ];
  }, []);

  const putOperationOnTrash = useCallback(
    async operationCode => {
      try {
        activeLoader(true);
        await api.post(
          `/credits/api/v1/opcredit-manager/${operationCode}/delete/`,
        );
        getOperations();
        activeLoader(false);
      } catch (err) {
        activeLoader(false);
        const { ...error } = err;
        console.log('error', error);
        setError(true);
        setErrorMessage('algum Erro aconteceu.');
      }
    },
    [activeLoader, getOperations],
  );

  const removeOperationOnTrash = useCallback(
    async operationCode => {
      try {
        activeLoader(true);
        await api.post(
          `/credits/api/v1/opcredit-manager/${operationCode}/undelete/`,
        );
        getOperations();
        activeLoader(false);
        setShowUndeleteOperation(false);
      } catch (err) {
        activeLoader(false);
        const { ...error } = err;
        console.log('error', error);
        setError(true);
        setErrorMessage('Slgum Erro aconteceu.');
      }
    },
    [activeLoader, getOperations],
  );

  useEffect(() => {
    activeLoader(true);
    getOperations();
    getCountOperations();
  }, [activeLoader, getCountOperations, getOperations]);

  const TableOperationData = useMemo(
    () => CreateTableData(operations),
    [operations, CreateTableData],
  );

  const TableOperationColumns = useMemo(
    () => CreateTableColumns(OperationActions),
    [OperationActions, CreateTableColumns],
  );

  const TableDeletedOperationData = useMemo(
    () => CreateTableData(deletedOperations),
    [deletedOperations, CreateTableData],
  );

  const TableDeletedOperationColumns = useMemo(
    () => CreateTableColumns(DeletedOperationActions),
    [DeletedOperationActions, CreateTableColumns],
  );

  const TableDoneOperationData = useMemo(
    () => CreateTableData(doneOperations),
    [doneOperations, CreateTableData],
  );

  const TableDoneOperationColumns = useMemo(
    () => CreateTableColumns(OperationDoneActions),
    [OperationDoneActions, CreateTableColumns],
  );

  return (
    <Container>
      <DashboardLayout title="Monitoramento das Operações de Crédito">
        <Row>
          <Column small="12" medium="6" large="6">
            <TotalizerCard color="blue">
              <h1>{countOperation.count_operation}</h1>
              <small>Operações Contratadas</small>
            </TotalizerCard>
          </Column>
          <Column small="12" medium="6" large="6">
            <TotalizerCard color="dark-blue">
              <h1>{`R$ ${countOperation.representation_value}`}</h1>
              <small>Valor Total Contratado</small>
            </TotalizerCard>
          </Column>
        </Row>
        <Row>
          <Column small="12" medium="12" large="12">
            <button onClick={() => setShowModal(true)} className="reportButton">
              Gerar Relatório executivo
            </button>
          </Column>
        </Row>
        <TableContainer>
          <h1 className="tableTitle">Operações de crédito Ativas</h1>
          <DataTable
            columns={TableOperationColumns}
            data={TableOperationData}
            noDataComponent=""
            noHeader
          />
        </TableContainer>

        {!!TableDoneOperationData.length && (
          <TableContainer>
            <h1 className="tableTitle">Operações de crédito Concluídas</h1>
            <DataTable
              columns={TableDoneOperationColumns}
              data={TableDoneOperationData}
              noDataComponent=""
              noHeader
            />
          </TableContainer>
        )}

        {!!TableDeletedOperationData.length && (
          <TableContainer>
            <h1 className="tableTitle">Operações de crédito Inativas</h1>
            <DataTable
              columns={TableDeletedOperationColumns}
              data={TableDeletedOperationData}
              noDataComponent=""
              noHeader
            />
          </TableContainer>
        )}

        {showModal && (
          <ExecutiveReportModal
            handleClose={() => setShowModal(!showModal)}
            handleSuccess={() => {
              console.log('sucesso');
            }}
            show={showModal}
          />
        )}

        {showConcludeModal && (
          <ConfirmationModal
            handleClose={() => setShowConcludeModal(!showConcludeModal)}
            handleSuccess={() => handleConcludeOperation(operationToConclude)}
            show={showConcludeModal}
            title="Você tem certeza que deseja Concluir essa operação de crédito?"
          />
        )}

        {showDesconcludeModal && (
          <ConfirmationModal
            handleClose={() => setShowDesconcludeModal(!showDesconcludeModal)}
            handleSuccess={() =>
              handleDesConcludeOperation(operationToDesconclude)
            }
            show={showDesconcludeModal}
            title="Você tem certeza que deseja Reativar essa operação de crédito?"
          />
        )}

        {showDeleteOperation && (
          <ConfirmationModal
            handleClose={() => setShowDeleteOperation(!showDeleteOperation)}
            handleSuccess={() => putOperationOnTrash(operationToDelete)}
            show={showDeleteOperation}
            title="Você tem certeza que deseja inativar essa operação de crédito?"
          />
        )}

        {showUndeleteOperation && (
          <ConfirmationModal
            handleClose={() => setShowUndeleteOperation(!showUndeleteOperation)}
            handleSuccess={() => removeOperationOnTrash(operationToUndelete)}
            show={showUndeleteOperation}
            title="Você tem certeza que deseja reativar essa operação de crédito?"
          />
        )}

        {error && (
          <ErrorModal
            show={error}
            Message={errorMessage}
            handleClose={() => setError(false)}
          />
        )}
      </DashboardLayout>
    </Container>
  );
}

export default ListCreditOperation;
