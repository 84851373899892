/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import api from '../../services/api';
import getValidationErrors from '../../services/getValidationErrors';
import { isBefore, isAfter, isEqual } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import DashboardLayout from '../../components/DashboardLayout';
import DataTable from 'react-data-table-component';
import CriticalComments from '../CreditOperationMonitoring/modals/CriticalComments';
import { useHistory } from 'react-router-dom';
import { useCreditOperation } from '../../hooks/creditOperation';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import SuccessModal from '../../components/SuccessModal';
import ErrorModal from '../../components/ErrorModal';
import AddCriticalMilestone from './modals/AddCriticalMilestone';
import { MdAddCircle, MdEdit } from 'react-icons/md';
import {
  Container,
  TitleButton,
  Title,
  BackButton,
  ProgramationContainer,
  Card,
  AddDeliverableButton,
  ProjectTitle,
  CriticalMilestoneCard,
} from './styles';
import { Row, Column } from '../../styles/components';

const EditProjectDeliverables = () => {
  const formRef = useRef(null);
  const TableFormRef = useRef(null);
  const { goBack, push } = useHistory();
  const { projectId, deliverableId } = useParams();
  const { creditOperation } = useCreditOperation();
  const [project, setProject] = useState({});
  const [deliverable, setDeliverable] = useState({});
  const [programming, setProgramming] = useState([]);
  const [modalCritical, setModalCritical] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [endYear, setEndYear] = useState('');
  const [initYear, setInitYear] = useState('');
  const [criticalMilestones, setCriticalMilestones] = useState([]);
  const [ShowAddCriticalMilestone, setShowAddCriticalMilestone] =
    useState(false);
  const [ShowEditCriticalMilestone, setShowEditCriticalMilestone] =
    useState(false);
  const [showCriticalComments, setShowCriticalComments] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const schema = Yup.object().shape({
    title: Yup.string().required('Titulo é obrigatório'),
    indicator: Yup.string().required('Indicador é obrigatório'),
    unit_measure: Yup.string().required('Unidade de medida é obrigatória'),
    ref_value: Yup.string().required('Valor de referência é obrigatório'),
    source: Yup.string().required('A Fonte é obrigatória'),
    resp_measure: Yup.string().required(
      'Responsável pela medida é obrigatório',
    ),
    deadline: Yup.string().matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      'Por favor preencha uma data de prazo válida',
    ),
  });

  const calculateYearsBetween = useCallback((startDate, endDate) => {
    const startYear = startDate.getYear() + 1900,
      endYear = endDate.getYear() + 1900,
      output = [];
    for (var year = startYear; year <= endYear; year++) output.push(year);
    return output;
  }, []);

  const generateProgramming = useCallback(
    (initYear, deadlineYear, programmingData) => {
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${deadlineYear}`),
      );
      const programmingArray = years.map(year => {
        const filteredYear = programmingData.filter(
          p => p.year === Number(year),
        )[0];
        return {
          year: year,
          january: !!filteredYear ? filteredYear.jan : 0,
          february: !!filteredYear ? filteredYear.feb : 0,
          march: !!filteredYear ? filteredYear.mar : 0,
          april: !!filteredYear ? filteredYear.apr : 0,
          may: !!filteredYear ? filteredYear.may : 0,
          june: !!filteredYear ? filteredYear.jun : 0,
          july: !!filteredYear ? filteredYear.jul : 0,
          august: !!filteredYear ? filteredYear.aug : 0,
          september: !!filteredYear ? filteredYear.sep : 0,
          october: !!filteredYear ? filteredYear.oct : 0,
          november: !!filteredYear ? filteredYear.nov : 0,
          dezembro: !!filteredYear ? filteredYear.dec : 0,
        };
      });
      setProgramming([...programmingArray]);
    },
    [calculateYearsBetween],
  );

  const getYearData = useCallback((data, year) => {
    const rename = {
      [`january${year}`]: 'jan',
      [`february${year}`]: 'feb',
      [`march${year}`]: 'mar',
      [`april${year}`]: 'apr',
      [`may${year}`]: 'may',
      [`june${year}`]: 'jun',
      [`july${year}`]: 'jul',
      [`august${year}`]: 'aug',
      [`september${year}`]: 'sep',
      [`october${year}`]: 'oct',
      [`november${year}`]: 'nov',
      [`dezembro${year}`]: 'dec',
    };
    var filteredYear = Object.keys(data).reduce((res, key) => {
      if (key.indexOf(year) !== -1) {
        res[rename[key]] = !!data[key] ? data[key] : 0;
      }
      return res;
    }, {});
    return { year, ...filteredYear };
  }, []);

  const changeEndYear = useCallback(() => {
    const value = formRef.current.getFieldValue('deadline').split('/');
    setDeliverable({
      ...deliverable,
      deadline: `${value[2]}-${value[1]}-${value[0]}`,
    });
  }, [deliverable]);

  useEffect(() => {
    api.get(`/credits/api/v1/entregable/${deliverableId}`).then(response => {
      setDeliverable(response.data);
      const splitedDate = response.data.deadline.split('-');
      if (!!Object.keys(response.data).length) {
        formRef.current?.setData({
          description: response.data.description,
          indicator: response.data.indicator,
          unit_measure: response.data.unit_measure,
          resp_measure: response.data.resp_measure,
          ref_value: response.data.ref_value,
          source: response.data.source,
          deadline: `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`,
        });
      }
    });

    const projectUrl = `/credits/api/v1/contracted-project/${projectId}`;
    api.get(projectUrl).then(response => {
      setProject(response.data);
    });

    const criticalMilestoneUrl = `/credits/api/v1/entregable/${deliverableId}/critical/`;
    api.get(criticalMilestoneUrl).then(response => {
      setCriticalMilestones(response.data.results);
    });
  }, [generateProgramming, deliverableId, projectId]);

  useEffect(() => {
    if (!!Object.keys(deliverable).length) {
      generateProgramming(
        initYear,
        deliverable.deadline.split('-')[0],
        deliverable.programming_entregable,
      );
    }
  }, [generateProgramming, endYear, initYear, deliverable]);

  const handleAddSuccess = useCallback(
    data => {
      setCriticalMilestones([...criticalMilestones, data]);
    },
    [criticalMilestones],
  );

  const handleEditSuccess = useCallback(
    _data => {
      const criticalMilestoneUrl = `/credits/api/v1/entregable/${deliverableId}/critical/`;
      api.get(criticalMilestoneUrl).then(response => {
        setCriticalMilestones(response.data.results);
      });
    },
    [deliverableId],
  );

  const handleEditCritical = useCallback((critical, type) => {
    setModalCritical(critical);
    type === 'critical' && setShowEditCriticalMilestone(true);
    type === 'comments' && setShowCriticalComments(true);
  }, []);

  const handleSave = useCallback(
    async data => {
      const tableData = TableFormRef.current.getData();
      const deadline = data['deadline'].split('/');
      const years = calculateYearsBetween(
        new Date(`Jan 1 ${initYear}`),
        new Date(`Jan 1 ${deadline[2]}`),
      );

      const realized_entregable = deliverable.realized_entregable.map(t => {
        delete t.entregable;
        return t;
      });

      const filteredYears = years.map(year => getYearData(tableData, year));
      const postData = {
        ...data,
        deadline: `${deadline[2]}-${deadline[1]}-${deadline[0]}`,
        goal: Number(data.goal),
        programming_entregable: filteredYears,
        realized_entregable: realized_entregable,
        contract_project: Number(projectId),
      };

      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });
        setLoadingSuccess(true);
        setShowSuccess(true);
        const response = await api.put(
          `/credits/api/v1/entregable/${deliverableId}/`,
          postData,
        );
        setDeliverable({ ...response.data });
        setLoadingSuccess(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoadingSuccess(false);
        setShowSuccess(false);
        const { ...error } = err;
        if (!!error.response.data.deadline) {
          setError(true);
          setErrorMessage(error.response.data.deadline);
        }
        if (!!error.response.data.programming_entregable) {
          setError(true);
          setErrorMessage(error.response.data.programming_entregable[0]);
        }
      }
    },
    [calculateYearsBetween, schema, getYearData, projectId, deliverableId],
  );

  useEffect(() => {
    if (!!Object.keys(creditOperation).length) {
      setInitYear(creditOperation.start_date.split('-')[0]);
      setEndYear(creditOperation.start_date.split('-')[0]);
    }
  }, [creditOperation]);

  const handleDisabled = useCallback((initDate, endDate, month, year) => {
    if (!!initDate && !!endDate) {
      const actualDate = new Date(`${month} 1 ${year}`);
      const newInitDate = new Date(
        `${initDate.split('-')[1]} 1  ${initDate.split('-')[0]}`,
      );
      const newEndDate = new Date(
        `${endDate.split('/')[1]} 1 ${endDate.split('/')[2]}`,
      );
      const initIsBefore = isBefore(actualDate, newInitDate);
      const endIsAfter = isAfter(actualDate, newEndDate);
      const isEqualInit = isEqual(actualDate, newInitDate);
      return (initIsBefore || endIsAfter) && !isEqualInit ? true : false;
    } else {
      return null;
    }
  }, []);

  const hasDisabled = useCallback((initDate, endDate, month, year) => {
    const value = handleDisabled(initDate, endDate, month, year);
    const actualDate = new Date(`${month} 1 ${year}`);
    const newInitDate = new Date(
      `${initDate.split('-')[1]} 1 ${initDate.split('-')[0]}`,
    );
    const initIsBefore = isBefore(actualDate, newInitDate);
    return !!value ? value : initIsBefore;
  }, []);

  const columns = [
    {
      name: 'Ano',
      selector: 'year',
    },
    {
      name: 'Jan',
      selector: 'january',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Jan',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`january${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.january === 0 ? null : row.january}
          />
        );
      },
    },
    {
      name: 'Fev',
      selector: 'february',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Feb',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`february${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.february === 0 ? null : row.february}
          />
        );
      },
    },
    {
      name: 'Mar',
      selector: 'march',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Mar',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`march${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.march === 0 ? null : row.march}
          />
        );
      },
    },
    {
      name: 'Abr',
      selector: 'april',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Apr',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`april${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.april === 0 ? null : row.april}
          />
        );
      },
    },
    {
      name: 'Mai',
      selector: 'may',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'May',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`may${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.may === 0 ? null : row.may}
          />
        );
      },
    },
    {
      name: 'Jun',
      selector: 'jun',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Jun',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`june${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.june === 0 ? null : row.june}
          />
        );
      },
    },
    {
      name: 'Jul',
      selector: 'jul',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Jul',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`july${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.july === 0 ? null : row.july}
          />
        );
      },
    },
    {
      name: 'Ago',
      selector: 'august',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Aug',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`august${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.august === 0 ? null : row.august}
          />
        );
      },
    },
    {
      name: 'Set',
      selector: 'september',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Sep',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`september${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.september === 0 ? null : row.september}
          />
        );
      },
    },
    {
      name: 'Out',
      selector: 'october',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Oct',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`october${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.october === 0 ? null : row.october}
          />
        );
      },
    },
    {
      name: 'Nov',
      selector: 'november',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Nov',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`november${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.november === 0 ? null : row.november}
          />
        );
      },
    },
    {
      name: 'Dez',
      selector: 'dezembro',
      minWidth: '210px',
      cell: row => {
        const disabledValue = hasDisabled(
          creditOperation.start_date,
          formRef?.current?.getFieldValue('deadline'),
          'Dec',
          row.year,
        );
        return (
          <Input
            disabled={disabledValue}
            type="number"
            name={`dezembro${row.year}`}
            placeholder="Digite o valor"
            defaultValue={row.dezembro === 0 ? null : row.dezembro}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <DashboardLayout title="Monitoramento da Operação de crédito">
        {!!Object.keys(deliverable).length && (
          <>
            <BackButton
              onClick={() =>
                push(`/projects/projetos-contratados/${projectId}`)
              }
            >
              Voltar
            </BackButton>
            <BackButton onClick={() => push('/projects/monitoramento-credito')}>
              Voltar para a operação de crédito
            </BackButton>
            <TitleButton>
              {`(${creditOperation.financing_entity} ${creditOperation.code}) ${creditOperation.operation_title}`}
            </TitleButton>
            <ProjectTitle>
              <h1>Projeto</h1>
              <span>{`${project.id}. ${project.title}`}</span>
            </ProjectTitle>
            <Title>Editar Entregável do Projeto</Title>
            <Form ref={formRef} onSubmit={handleSave}>
              <Row>
                <Column small="12" medium="12" large="12">
                  <Input
                    label="Titulo"
                    name="title"
                    className="title"
                    placeholder="Título"
                    defaultValue={deliverable.title}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="12" large="12">
                  <Input
                    label="Indicador"
                    name="indicator"
                    className="indicator"
                    placeholder="Indicador"
                    defaultValue={deliverable.indicator}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="4" large="3">
                  <Input
                    label="Unidade de Medida"
                    name="unit_measure"
                    className="unit_measure"
                    defaultValue={deliverable.unit_measure}
                    placeholder="Unidade de Medida"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="4" large="6">
                  <Input
                    label="Responsável pela Medição"
                    name="resp_measure"
                    className="resp_measure"
                    defaultValue={deliverable.resp_measure}
                    placeholder="Responsável pela Medição"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="4" large="3">
                  <Input
                    type="number"
                    label="Valor de Referência"
                    name="ref_value"
                    className="ref_value"
                    defaultValue={deliverable.ref_value}
                    placeholder="Valor de Referência"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="4" large="4">
                  <Input
                    label="Fonte"
                    name="source"
                    className="source"
                    defaultValue={deliverable.source}
                    placeholder="Fonte"
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="4" large="4">
                  <Input
                    label="Meta"
                    name="goal"
                    className="goal"
                    placeholder="Meta"
                    defaultValue={deliverable.goal}
                    schema={schema}
                  />
                </Column>
                <Column small="12" medium="4" large="4">
                  <InputMask
                    label="Prazo Final"
                    name="deadline"
                    className="deadline"
                    placeholder="Prazo Final"
                    mask="99/99/9999"
                    maskChar={null}
                    defaultValue={`${deliverable.deadline.split('-')[2]}/${
                      deliverable.deadline.split('-')[1]
                    }/${deliverable.deadline.split('-')[0]}`}
                    onChange={changeEndYear}
                    schema={schema}
                  />
                </Column>
              </Row>
              <Form ref={TableFormRef} onSubmit={handleSave}>
                <ProgramationContainer>
                  <h1>Programação :</h1>
                  <DataTable
                    columns={columns}
                    data={programming}
                    noDataComponent=""
                    noHeader
                  />
                </ProgramationContainer>
              </Form>
              <Card shadow>
                <Title>Adicicionar Marcos Críticos</Title>
                <Row>
                  <Column small="12" medium="6" large="3">
                    <AddDeliverableButton
                      onClick={() => setShowAddCriticalMilestone(true)}
                    >
                      <MdAddCircle size={55} color="#AAB9BF" />
                      <span>Adicionar</span>
                    </AddDeliverableButton>
                  </Column>
                  {!!criticalMilestones.length &&
                    criticalMilestones.map(critical => (
                      <Column key={critical.id} small="12" medium="6" large="3">
                        <CriticalMilestoneCard>
                          <header>
                            <h1>{`${critical.name}`}</h1>
                            <span>
                              Prazo:
                              {format(
                                parseISO(critical.deadline),
                                'dd/MM/yyyy',
                              )}
                            </span>
                            <span className="span-icon">
                              <button
                                type="button"
                                onClick={() =>
                                  handleEditCritical(critical, 'comments')
                                }
                              >
                                Cadastrar comentários
                              </button>
                              <MdEdit
                                onClick={() =>
                                  handleEditCritical(critical, 'critical')
                                }
                                size={18}
                                color="#ffffff"
                              />
                            </span>
                          </header>
                          <div className="responsible-container">
                            <h1>Ponto Focal Responsável</h1>
                            <span>
                              <b>Nome</b>: {critical.responsible.name}
                            </span>
                            <span>
                              <b>Orgão</b>: {critical.responsible.org}
                            </span>
                            <span>
                              <b>Cargo</b>: {critical.responsible.job}
                            </span>
                            <span>
                              <b>Email</b>: {critical.responsible.email}
                            </span>
                            <span>
                              <b>Telefone</b>: {critical.responsible.phone}
                            </span>
                          </div>
                        </CriticalMilestoneCard>
                      </Column>
                    ))}
                </Row>
              </Card>
              {ShowAddCriticalMilestone && (
                <AddCriticalMilestone
                  handleClose={() =>
                    setShowAddCriticalMilestone(!ShowAddCriticalMilestone)
                  }
                  handleSuccess={data => handleAddSuccess(data)}
                  deliverableId={deliverableId}
                  show={ShowAddCriticalMilestone}
                />
              )}
              {ShowEditCriticalMilestone && (
                <AddCriticalMilestone
                  handleClose={() =>
                    setShowEditCriticalMilestone(!ShowEditCriticalMilestone)
                  }
                  handleEditSuccess={data => handleEditSuccess(data)}
                  deliverableId={deliverableId}
                  critical={modalCritical}
                  show={ShowEditCriticalMilestone}
                />
              )}
              {showCriticalComments && (
                <CriticalComments
                  handleClose={() =>
                    setShowCriticalComments(!showCriticalComments)
                  }
                  show={showCriticalComments}
                  critical={modalCritical}
                />
              )}
              <button className="save" type="submit">
                salvar
              </button>
            </Form>
            {showSuccess && (
              <SuccessModal
                loading={loadingSuccess}
                handleClose={() => setShowSuccess(false)}
                show={showSuccess}
                successText="Entregável Editado com Sucesso!!"
              />
            )}
            {error && (
              <ErrorModal
                show={error}
                Message={errorMessage}
                handleClose={() => setError(false)}
              />
            )}
          </>
        )}
      </DashboardLayout>
    </Container>
  );
};

export default EditProjectDeliverables;
