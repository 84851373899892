import React, { useCallback } from 'react';
import { ReactComponent as Algemas } from '../../../../../assets/algemas.svg';
import { ReactComponent as Hands } from '../../../../../assets/hands.svg';
import { ReactComponent as Government } from '../../../../../assets/government.svg';

import { ReactComponent as Like } from '../../../../../assets/like.svg';
import { FaThumbsUp } from 'react-icons/fa';

import {
  Container,
  Title,
  TitleText,
  Card,
  CardTitle,
  CardItem,
  IndicatorCard,
  IndicatorText,
  IconContainer,
  OtherIndicators,
  IndicatorItem,
} from './styles';

function Page2({ data, printing }) {
  const getColor = useCallback(value => {
    const colors = {
      '-1': '#ff534a',
      0: '#bfbfbf',
      1: '#5fb346',
      2: '#bfbfbf',
    };
    return colors[value];
  }, []);

  return (
    <Container>
      <Title>Destaques positivos</Title>
      <TitleText>
        Nesta seção são apresentados os indicadores que tiveram desempenho acima
        da média em comparação com seus grupos de análise (acima da média
        nacional, acima da média regional etc.). Em destaque encontram-se os
        cinco indicadores que obtiveram o melhor desempenho no período de
        referência. Ao final da página, você tem acesso aos demais indicadores
        que se destacaram positivamente.
      </TitleText>
      {data.category.map(category => (
        <Card printing={printing}>
          <CardTitle
            color={
              category.category_name === 'Criminalidade e Violência'
                ? '#0c9fd8'
                : category.category_name === 'Desempenho Institucional'
                ? '#00486B'
                : category.category_name === 'Fatores de Risco'
                ? '#007BAC'
                : '#0c9fd8'
            }
          >
            {category.category_name === 'Criminalidade e Violência' && (
              <Algemas />
            )}
            {category.category_name === 'Desempenho Institucional' && (
              <Government />
            )}
            {category.category_name === 'Fatores de Risco' && <Hands />}
            {category.category_name}
          </CardTitle>

          {category.bests_indicators.map(indicator => (
            <CardItem>
              <IndicatorCard printing={printing}>
                <div className="titleContainer">
                  <h1>{indicator.name}</h1>
                  <small>{indicator.source}</small>
                </div>
                <div className="buttonsContainer">
                  <div className="IconContent">
                    <IconContainer color={getColor(indicator.evolution_cmp)}>
                      {indicator.evolution_cmp === 0 && (
                        <img src="/static/media/period-grey.png" alt="" />
                      )}
                      {indicator.evolution_cmp === 2 && (
                        <img src="/static/media/period-empty.png" alt="" />
                      )}

                      {indicator.evolution_cmp === -1 && <Like />}
                      {indicator.evolution_cmp === 1 && <FaThumbsUp />}
                    </IconContainer>
                    {(data.is_state ||
                      (data.is_capital &&
                        !data.is_metropolitan_city &&
                        data.is_county &&
                        data.population > 100000)) && (
                      <IconContainer color={getColor(indicator.region_cmp)}>
                        {indicator.region_cmp === -1 && (
                          <img src="/static/media/region-red.png" alt="" />
                        )}
                        {indicator.region_cmp === 0 && (
                          <img src="/static/media/region-grey.png" alt="" />
                        )}
                        {indicator.region_cmp === 1 && (
                          <img src="/static/media/region-green.png" alt="" />
                        )}
                        {indicator.region_cmp === 2 && (
                          <img src="/static/media/region-empty.png" alt="" />
                        )}
                      </IconContainer>
                    )}
                    <IconContainer color={getColor(indicator.country_cmp)}>
                      {indicator.country_cmp === -1 && (
                        <img src="/static/media/flag-red.png" alt="" />
                      )}
                      {indicator.country_cmp === 0 && (
                        <img src="/static/media/flag-grey.png" alt="" />
                      )}
                      {indicator.country_cmp === 1 && (
                        <img src="/static/media/flag-green.png" alt="" />
                      )}
                      {indicator.country_cmp === 2 && (
                        <img src="/static/media/flag-empty.png" alt="" />
                      )}
                    </IconContainer>
                    {!data.is_state && (
                      <IconContainer color={getColor(indicator.state_cmp)}>
                        {indicator.state_cmp === -1 && (
                          <img src="/static/media/region-red.png" alt="" />
                        )}
                        {indicator.state_cmp === 0 && (
                          <img src="/static/media/region-grey.png" alt="" />
                        )}
                        {indicator.state_cmp === 1 && (
                          <img src="/static/media/region-green.png" alt="" />
                        )}
                        {indicator.state_cmp === 2 && (
                          <img src="/static/media/region-empty.png" alt="" />
                        )}
                      </IconContainer>
                    )}
                    {((data.is_capital && data.is_metropolitan_city) ||
                      (data.is_county && data.is_metropolitan_city)) && (
                      <IconContainer color={getColor(indicator.metropolis_cmp)}>
                        {indicator.metropolis_cmp === -1 && (
                          <img src="/static/media/metrop-red.png" alt="" />
                        )}
                        {indicator.metropolis_cmp === 0 && (
                          <img src="/static/media/metrop-grey.png" alt="" />
                        )}
                        {indicator.metropolis_cmp === 1 && (
                          <img src="/static/media/metrop-green.png" alt="" />
                        )}
                        {indicator.metropolis_cmp === 2 && (
                          <img src="/static/media/metrop-empty.png" alt="" />
                        )}
                      </IconContainer>
                    )}
                  </div>
                  <span>
                    Período:
                    <br /> {indicator.period}
                  </span>
                </div>
              </IndicatorCard>
              <IndicatorText
                dangerouslySetInnerHTML={{
                  __html: indicator.content,
                }}
              ></IndicatorText>
            </CardItem>
          ))}
          {!category.bests_indicators.length && (
            <span>
              O estado/município analisado não possui indicadores com
              performance positiva em todos os critérios avaliados (performance
              no passado recente e comparações com as médias nacional e
              regional/estadual).
            </span>
          )}
        </Card>
      ))}
      <Card printing={printing}>
        <OtherIndicators>
          <div className="title">
            <IconContainer color="#5fb346">
              <FaThumbsUp />
            </IconContainer>
            Demais indicadores com destaque positivo:
          </div>
          <IndicatorItem>
            {data.category[2].positive_performance.map(indicator => (
              <span>{`${indicator}, `}</span>
            ))}
            {data.category[1].positive_performance.map(indicator => (
              <span>{`${indicator}, `}</span>
            ))}
            {data.category[0].positive_performance.map(indicator => (
              <span>{`${indicator}, `}</span>
            ))}
          </IndicatorItem>
        </OtherIndicators>
      </Card>
    </Container>
  );
}

export default Page2;
