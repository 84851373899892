import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DashBoardLayout from '../../components/DashboardLayout';
import { Container, Topic, BackButton, AnswerCard } from './styles';
import Mailto from '../../components/Mailto';
import { FaThumbsDown } from 'react-icons/fa';
import { FaThunbsUp } from 'react-icons/fa';
import { Column, Row } from '../../styles/components';
import api from '../../services/api';

const BankOfDoubtsDetail = () => {
  const { goBack } = useHistory();
  const { topicId } = useParams();
  const [topic, setTopic] = useState({});
  const [formatedTopic, setFormatedTopic] = useState({});

  const getTopic = useCallback(async () => {
    try {
      const topics = await api.get('/learning/api/v1/topics/');

      const filteredTopic = topics.data.results.filter(
        item => item.id == topicId,
      )[0];
      setTopic(filteredTopic);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const Like = useCallback(async ask => {
    try {
      const url = `/learning/api/v1/ask/${ask.id}`;
      const data = {
        ...ask,
        like: ask.like + 1,
      };
      const response = api.patch(url, { answer_ask: data });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getTopic();
  }, []);

  useMemo(async () => {
    if (Object.keys(topic).length) {
      const asks = await api.get(`/learning/api/v1/ask/?topic=${topic.id}`);
      setFormatedTopic({ ...topic, asks: asks.data.results });
      return { ...topic, asks: asks.data.results };
    }
  }, [topic]);

  return (
    <Container>
      <DashBoardLayout title="Banco de Dúvidas">
        <Row>
          <BackButton onClick={() => goBack()}>Voltar</BackButton>
          <Column large="12" medium="12" small="12">
            <Topic>
              <h1>{formatedTopic.name}</h1>
            </Topic>
            {!!formatedTopic.asks && (
              <>
                {formatedTopic.asks.map(ask => (
                  <div key={`ask${ask.id}`}>
                    <div className="askContainer">
                      <h2>{ask.description}</h2>
                    </div>
                    <div>
                      {ask.answer_ask.map(answer => (
                        <AnswerCard key={`answer${answer.id}`}>
                          <small>{answer.user_name}</small>
                          {answer.description}
                          {/* <div className="likeContainer">
                            <div className="up" onClick={() => Like(answer)}>
                              <FaThumbsDown size={22} className="rotate" />
                              <small>{answer.like}</small>
                            </div>
                            <div className="down">
                              <FaThumbsDown size={22} />
                              <small>{answer.dislike}</small>
                            </div>
                          </div> */}
                        </AnswerCard>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </Column>
        </Row>
        <Row>
          <Column large="12" medium="12" small="12">
            <Mailto mailto="mailto:macroplan@gmail.com">
              Não encontrou sua dúvida? Clique aqui
            </Mailto>
          </Column>
        </Row>
      </DashBoardLayout>
    </Container>
  );
};

export default BankOfDoubtsDetail;
