import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Row, Column } from '../../../styles/components';
import ExternalLayout from '../../../components/ExternalLayout';
import TabContent from './components/TabContent';
import Categories from './components/Categories';
import CategoryModal from './components/CategoryModal';
import { Container, MainContent } from './styles';
import api from '../../../services/api';

function MethodologyDiagnosis() {
  const { goBack } = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [tab, setTab] = useState(1);
  const [ options, setOptions ] = useState(undefined)

  const getOptions = useCallback(async () => {
    const { data } = await api.get('/api/v1/indicator-methodology/')
    if (data) {
      setOptions(data)
    }
  }, [])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  useEffect(() => {
    let timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const selectOption = useCallback(option => {
    setSelectedOption(option);
    setShowModal(true);
  }, []);

  return (
    <ExternalLayout headerTransparent={true}>
      <Container tab={tab}>
        <MainContent>
          <div className="imageWrapper">
            <div className="imageContent">
              <div className="titleContent">Metodologia</div>
            </div>
          </div>
          <div className="content">
            <button className="gobackLink" onClick={goBack}>
              Voltar
            </button>
            <div className="title">Diagnóstico de Indicadores</div>
            <div className="text">
              <p>
                O diagnóstico de indicadores é uma ferramenta executiva que tem
                como objetivo de permitir a contextualização da situação de cada
                estado e município brasileiro na área da segurança pública.
                &nbsp;
                <b>
                  O diagnóstico conta com indicadores atualizados, que permitem
                  a avaliação da evolução histórica de cada ente federativo, bem
                  como sua comparação com os seus pares.
                </b>
                &nbsp; Esses indicadores encontram-se organizados em 03
                tipologias básicas, apresentadas abaixo:
              </p>
            </div>
            <Row style={{ marginTop: '20px' }} className="TabsContainer">
              <Column large="12" medium="12" small="12">
                {/* <Link
                  to="/metodologia/indicador-sintetico"
                  className="redirectLink"
                >
                  Saiba mais sobre os indicadores selecionados
                </Link> */}
              </Column>
              <Categories setTab={setTab} />
            </Row>
            <Row>
              <Column large="12" medium="12" small="12">
                {tab === 1 && (
                  <TabContent
                    selectOption={selectOption}
                    options={options?.criminalidade_violencia || []}
                    title="Criminalidade e Violência"
                    subtitle="Indicadores finalísticos de mensuração das ocorrências e/ou de quantificação das vitimizações de acordo com os principais variáveis da criminalidade violenta."
                  />
                )}
                {tab === 2 && (
                  <TabContent
                    selectOption={selectOption}
                    options={options?.fatores_risco || []}
                    title="Fatores de Risco"
                    subtitle="Indicadores intermediários de mensuração da incidência de determinados fatores de risco/protetivos e determinantes individuais/socioeconômicos e condicionantes situacionais/ambientais."
                  />
                )}
                {tab === 3 && (
                  <TabContent
                    selectOption={selectOption}
                    options={options?.desempenho_institucional || []}
                    title="Desempenho Institucional"
                    subtitle="Indicadores de esforço, vinculados à capacidade das instituições públicas de garantir serviços de educação, saúde, justiça e segurança adequados à prevenção, mitigação, combate aos fenômenos e problemas associados à segurança pública e justiça"
                  />
                )}
              </Column>
            </Row>
            <div className="text">
              <p>
                Além dos indicadores coletados, pela plataforma, a partir de
                fontes públicas oficiais de base nacional, &nbsp;
                <b>
                  na área logada da plataforma será possível aos gestores
                  públicos agregarem indicadores locais ao diagnóstico,
                </b>
                &nbsp; tendo em vista a complementação do mesmo a partir dos
                dados disponíveis em bases de registros administrativos
                estaduais e municipais.
              </p>
            </div>
          </div>
        </MainContent>
        {showModal && (
          <CategoryModal
            option={selectedOption}
            show={showModal}
            handleClose={() => setShowModal(false)}
          />
        )}
      </Container>
    </ExternalLayout>
  );
}

export default MethodologyDiagnosis;
