import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useLoader } from '../../hooks/loader';
import { parseISO, format } from 'date-fns';
import { formatPrice } from '../../services/currencyFormatter';
import DashboardLayout from '../../components/DashboardLayout';
import Toggle from 'react-toggle';
import Table from '../../components/Table';
import AddOperationModal from '../ManagementMonitoringAdmin/modals/AddOperation';
import PercentageBar from '../../components/PercentageBar';
import TimePercentageBar from '../../components/TimePercentageBar';
import Button from '../../components/Button';
import Accordion from '../../components/Accordion';
import DeliverablesCriticalMilestones from '../CreditOperationMonitoring/modals/DeliverablesCriticalMilestones';
import StandardDocuments from '../../components/StandardDocuments';
import api from '../../services/api';
import AddTargetAudience from '../CreditOperationMonitoring/modals/AddTargetAudience';
import AddMainGoal from '../CreditOperationMonitoring/modals/AddMainGoal';
import { IoIosPaper } from 'react-icons/io';
import {
  FaEdit,
  FaInfoCircle,
  FaSearch,
  FaEnvelope,
  FaFilePdf,
} from 'react-icons/fa';

import {
  Container,
  TitleButton,
  BackButton,
  OptionsContainer,
  Card,
  CardTitle,
  MetroSearch,
  BlueContainer,
  Separator,
  ProjectsContainer,
  HeaderCard,
  ToggleContainer,
  AddButton,
  TableContainer,
} from './styles';

const CreditOperationMonitoringAdmin = () => {
  const { goBack, push } = useHistory();
  const { activeLoader } = useLoader();
  const { operationCode } = useParams();
  const [showMainGoal, setShowMainGoal] = useState(false);
  const [showTargetAudience, setShowTargetAudience] = useState(false);
  const [showStandardDocuments, setShowStandardDocuments] = useState(false);
  const [showDeliverables, setShowDeliverables] = useState(false);
  const [isExecution, setIsExecution] = useState(false);
  const [projects, setProjects] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [governances, setGovernances] = useState([]);
  const [creditOperation, setCreditOperation] = useState({});
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [agreedResults, setAgreedResults] = useState(false);

  const getCreditOperations = useCallback(async () => {
    try {
      const response = await api.get('/credits/api/v1/opcredit-manager/');
      const filteredOperation = response.data.results.filter(
        op => op.code === operationCode,
      )[0];
      setCreditOperation(filteredOperation);

      if (!!filteredOperation) {
        setIsExecution(!!(filteredOperation.status === 'execution'));

        const apiImpactsUrl = `/credits/api/v1/opcredit-manager/${filteredOperation.code}/desired-impacts/`;
        api.get(apiImpactsUrl).then(response => {
          setImpacts(response.data.results);
        });

        const apiGovernanceUrl = `/credits/api/v1/opcredit-manager/${filteredOperation.code}/governance/`;
        api.get(apiGovernanceUrl).then(response => {
          setGovernances(response.data.results);
        });
      }
    } catch (err) {
      activeLoader(false);
      console.error(err);
    }
  }, [activeLoader, operationCode]);

  const getAgreeedResults = useCallback(() => {
    const apiUrl = `/credits/api/v1/opcredit-manager/${operationCode}/agreed-results/`;
    api.get(apiUrl).then(response => {
      setAgreedResults(response.data.results);
    });
  }, [operationCode]);

  const getContractedProjects = useCallback(async () => {
    const apiUrl = `/credits/api/v1/contracted-project/?operation=${operationCode}`;
    try {
      const response = await api.get(apiUrl);
      const unresolvedPromises = response.data.results.map(async project => {
        const responsibles = await api.get(
          `/credits/api/v1/contracted-project/${project.id}/responsible/`,
        );
        const filteredResponsibles = responsibles.data.results.filter(
          resp => resp.contract_project === Number(project.id),
        );
        project.responsibles = filteredResponsibles;
        return project;
      });
      const promisesResponse = await Promise.all(unresolvedPromises);
      setProjects(promisesResponse);
      activeLoader(false);
    } catch (err) {
      console.error(err);
    }
  }, [activeLoader]);

  const handleChangeOperationStatus = useCallback(async () => {
    try {
      const apiUrl = `/credits/api/v1/opcredit-manager/${creditOperation.code}/status/`;
      const response = await api.post(apiUrl, {
        status:
          creditOperation.status === 'execution' ? 'structuring' : 'execution',
      });
      setCreditOperation(response.data);
      setIsExecution(!!(response.data.status === 'execution'));
    } catch (err) {
      console.error(err);
    }
  }, [creditOperation.status, creditOperation.code]);

  useEffect(() => {
    activeLoader(true);
    const getData = async () => {
      await getCreditOperations();
      getContractedProjects();
      getAgreeedResults();
      activeLoader(false);
    };
    getData();
  }, [getCreditOperations, activeLoader, getContractedProjects]);

  const handleDeliverablesModal = useCallback(async projectId => {
    setProjectId(projectId);
    setShowDeliverables(true);
  }, []);

  const handleEditSuccess = useCallback(() => {
    const getData = async () => {
      await getCreditOperations();
      getContractedProjects();
    };
    getData();
  }, [getCreditOperations, getContractedProjects]);

  const handleAddSuccess = useCallback(() => {
    getCreditOperations();
  }, []);

  return (
    <Container>
      <DashboardLayout title="Monitoramento da Operação de crédito">
        {!!Object.keys(creditOperation).length && (
          <>
            <BackButton onClick={() => goBack()}>Voltar</BackButton>
            <TitleButton>{`(${creditOperation.financing_entity} ${creditOperation.code}) ${creditOperation.operation_title}`}</TitleButton>
            <OptionsContainer>
              {creditOperation.status === 'structuring' && (
                <div onClick={() => setShowOperationModal(true)}>
                  <FaEdit />
                  Editar
                </div>
              )}
              {showOperationModal && (
                <AddOperationModal
                  handleClose={() => setShowOperationModal(!showOperationModal)}
                  handleEditSuccess={() => handleEditSuccess()}
                  show={showOperationModal}
                  operation={creditOperation}
                />
              )}
            </OptionsContainer>
            <Card shadow>
              <div className="content">
                <div className="info">
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Público Alvo:
                    </CardTitle>
                    <span>{creditOperation.target_public}</span>
                    {!!creditOperation.target_public && (
                      <>
                        <AddButton
                          show={!!(creditOperation.status === 'structuring')}
                          onClick={() => setShowTargetAudience(true)}
                        >
                          Editar
                        </AddButton>
                      </>
                    )}
                    {!creditOperation.target_public && (
                      <AddButton
                        show={!!(creditOperation.status === 'structuring')}
                        onClick={() => setShowTargetAudience(true)}
                      >
                        + Adicionar
                      </AddButton>
                    )}
                    {showTargetAudience && (
                      <AddTargetAudience
                        handleClose={() =>
                          setShowTargetAudience(!showTargetAudience)
                        }
                        handleSuccess={() => handleAddSuccess()}
                        show={showTargetAudience}
                        creditOperation={creditOperation}
                      />
                    )}
                  </div>

                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Objetivo Geral:
                    </CardTitle>
                    {!!creditOperation.gen_goal && (
                      <>
                        <span>{creditOperation.gen_goal}</span>
                        <AddButton
                          show={!!(creditOperation.status === 'structuring')}
                          onClick={() => setShowMainGoal(true)}
                        >
                          Editar
                        </AddButton>
                      </>
                    )}
                    {!creditOperation.gen_goal && (
                      <AddButton
                        show={!!(creditOperation.status === 'structuring')}
                        onClick={() => setShowMainGoal(true)}
                      >
                        + Adicionar
                      </AddButton>
                    )}
                    {showMainGoal && (
                      <AddMainGoal
                        handleClose={() => setShowMainGoal(!showMainGoal)}
                        handleSuccess={data => handleAddSuccess(data)}
                        show={showMainGoal}
                        creditOperation={creditOperation}
                      />
                    )}
                  </div>
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Impactos Desejados:
                    </CardTitle>
                    {impacts.map(impacto => (
                      <div key={impacto.id}>
                        <p style={{ marginBottom: '8px' }}>
                          {impacto.impact_indicator.impact_name}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="container-text">
                    <CardTitle>
                      <FaInfoCircle />
                      Governança (Linha Gerencial):
                    </CardTitle>
                    {governances.map(governance => (
                      <div key={governance.id}>
                        <p>
                          <FaEnvelope color="#0C9FD8" size={16} />
                          <b>{governance.job}:&nbsp;</b> {governance.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="cards">
                  {creditOperation.status === 'structuring' &&
                    !creditOperation.is_delete && (
                      <HeaderCard background="red">
                        Projeto em Estruturação
                      </HeaderCard>
                    )}

                  {creditOperation.status === 'execution' &&
                    !creditOperation.is_delete && (
                      <HeaderCard background="dark-green">
                        Estágio de Execução
                      </HeaderCard>
                    )}

                  {creditOperation.status === 'done' &&
                    !creditOperation.is_delete && (
                      <HeaderCard background="dark-green">
                        Projeto Concluido
                      </HeaderCard>
                    )}

                  {creditOperation.is_delete && (
                    <HeaderCard background="text">Projeto Inativo</HeaderCard>
                  )}

                  <div className="content">
                    <>
                      <div className="bar-container">
                        <PercentageBar
                          percentage={creditOperation?.execution?.physics}
                          title="Execução Fisica"
                          color="yellow"
                        />
                      </div>
                      <div className="bar-container">
                        <PercentageBar
                          percentage={creditOperation?.execution?.finance}
                          title="Execução Financeira"
                          color="green"
                        />
                      </div>
                      <div className="bar-container">
                        <TimePercentageBar
                          percentage={creditOperation.execution.time_month}
                          color="blue"
                          title="Tempo (meses)"
                        />
                      </div>
                    </>
                  </div>
                  {!creditOperation.is_delete &&
                    creditOperation.status !== 'done' && (
                      <>
                        <CardTitle>Operação Em:</CardTitle>
                        <ToggleContainer status={creditOperation.status}>
                          <small>Estruturação</small>
                          <Toggle
                            defaultChecked={isExecution}
                            icons={false}
                            className="statusToggle"
                            onChange={handleChangeOperationStatus}
                          />
                          <span>Execução</span>
                        </ToggleContainer>
                      </>
                    )}
                  <BlueContainer>
                    <CardTitle>Montante Financeiro</CardTitle>
                    <span>
                      <b>Valor Total:&nbsp;</b>
                      {`${formatPrice(creditOperation.global_value.value)}`}
                    </span>
                    <span>
                      <b>Execução Financeira:&nbsp;</b>
                      {!!creditOperation.execution &&
                        `${creditOperation.execution.finance}%`}
                      <MetroSearch
                        onClick={() =>
                          push(
                            `/management/execucao-financeira/${operationCode}`,
                          )
                        }
                      >
                        <FaSearch />
                      </MetroSearch>
                    </span>
                  </BlueContainer>
                  <BlueContainer>
                    <CardTitle>
                      Data de início e conclusão da operação
                    </CardTitle>
                    <span>
                      <b>Data de inicio:&nbsp;</b>
                      {format(
                        parseISO(creditOperation.start_date),
                        'dd/MM/yyyy',
                      )}
                    </span>
                    <span>
                      <b>Data de Termino:&nbsp;</b>
                      {format(parseISO(creditOperation.end_date), 'dd/MM/yyyy')}
                    </span>
                  </BlueContainer>
                  <Button
                    color="gray3"
                    onClick={() => setShowStandardDocuments(true)}
                  >
                    <IoIosPaper /> Acessar Documentos de Referência
                  </Button>
                  {showStandardDocuments && (
                    <StandardDocuments
                      handleClose={() =>
                        setShowStandardDocuments(!showStandardDocuments)
                      }
                      show={showStandardDocuments}
                      creditOperation={creditOperation}
                    />
                  )}
                  <Link
                    to={`/relatorio-executivo-2/${operationCode}`}
                    target="__blank"
                    color="dark-blue2"
                    className="linkButton"
                  >
                    <FaFilePdf /> Gerar Relatório Executivo
                  </Link>
                </div>
              </div>
              <TableContainer>
                <CardTitle>
                  <FaInfoCircle />
                  Resultados Pactuados:
                </CardTitle>
                <Table data={agreedResults} resultCallback={() => {}} />
              </TableContainer>
              <Separator />
              <ProjectsContainer>
                <CardTitle>
                  <FaInfoCircle />
                  Projetos Contratados:
                </CardTitle>
                {!!projects.length &&
                  projects.map(project => (
                    <Accordion
                      key={project.id}
                      title={`${project.id}. ${project.title}`}
                    >
                      <Card>
                        <div className="content">
                          <div className="info">
                            <div className="container-text">
                              <CardTitle>Descrição:</CardTitle>
                              <span>{project.title}</span>
                            </div>
                            <div className="container-text">
                              <CardTitle>Área de Resultado:</CardTitle>
                              <span>{project.result_area}</span>
                            </div>
                            <div className="container-text">
                              <CardTitle>Secretaria(s) Envolvida(s):</CardTitle>
                              {project.departament_project.map(secretaria => (
                                <span key={secretaria.id}>
                                  {secretaria.name}
                                </span>
                              ))}
                            </div>
                            <div className="container-text">
                              <CardTitle>Responsável:</CardTitle>
                              {project.responsibles.map(responsible => (
                                <span key={responsible.id}>
                                  {responsible.name}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="cards">
                            <div className="content">
                              <div className="bar-container">
                                <PercentageBar
                                  percentage={project.execution.physics}
                                  title="Execução Fisica"
                                  color="yellow"
                                />
                              </div>
                              <div className="bar-container">
                                <TimePercentageBar
                                  percentage={project.execution.time_month}
                                  color="blue"
                                  title="Tempo (meses)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="buttonContainer">
                          <Button
                            color="blue"
                            onClick={() => handleDeliverablesModal(project.id)}
                          >
                            <FaSearch /> Ver entregas e marcos Críticos
                          </Button>
                        </div>
                      </Card>
                    </Accordion>
                  ))}
                {showDeliverables && (
                  <DeliverablesCriticalMilestones
                    handleClose={() => setShowDeliverables(!showDeliverables)}
                    projectId={projectId}
                    operation={creditOperation}
                    show={showDeliverables}
                  />
                )}
              </ProjectsContainer>
            </Card>
          </>
        )}
      </DashboardLayout>
    </Container>
  );
};

export default CreditOperationMonitoringAdmin;
