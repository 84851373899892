import React, { useState, useEffect } from 'react';

import CasaFamilia from '../../../../../assets/casa-familia.png';
import Predio from '../../../../../assets/predio.png';
import Policia from '../../../../../assets/police-officer.png';
import Balanca from '../../../../../assets/balanca.png';
import ApertoMao from '../../../../../assets/aperto-mao.png';
import Lampada from '../../../../../assets/lampada.png';
import Modal from '../../../../../components/Modal';

import { Container, Content, ModalArea } from './styles';

function Estrutura() {
  const [modalYellow, setModalYellow] = useState(false);
  const [modalOrange, setModalOrange] = useState(false);
  const [modalRed, setModalRed] = useState(false);
  const [modalGreen, setModalGreen] = useState(false);
  const [modalPurple, setModalPurple] = useState(false);
  const [modalBlue, setModalBlue] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    console.log('effect');
    if (window.screen.width <= 994) {
      setIsMobile(true);
    }
  }, []);
  return (
    <Container>
      <h1>Estrutura do Programa</h1>
      <Content>
        <p>
          As soluções ofertadas no âmbito do <b>PROSEG Federativo</b> estão
          agrupadas em 06 Áreas de Resultado, as quais organizam os principais
          desafios estratégicos da segurança pública. Clique nos ícones abaixo
          para ver o que está contemplado em cada uma delas.
        </p>
        <ModalArea>
          <div
            className="click programa-yellow-card"
            role="button"
            onClick={() => setModalYellow(true)}
          >
            <div className="card-content">
              <img src={CasaFamilia} alt="" />
              <h4>Prevenção Social</h4>
            </div>
            <div className="click programa-card-footer">
              Veja os focos estratégicos
            </div>
          </div>

          <div className="margin" />

          <div
            className="click programa-orange-card"
            role="button"
            onClick={() => setModalOrange(true)}
          >
            <div className="card-content">
              <img src={Predio} alt="" />
              <h4>Prevenção Situacional</h4>
            </div>
            <div className="programa-card-footer">
              Veja os focos estratégicos
            </div>
          </div>

          <div className="margin" />

          <div
            className="click programa-red-card"
            role="button"
            onClick={() => setModalRed(true)}
          >
            <div className="card-content">
              <img src={Policia} alt="" />
              <h4>Policiamento</h4>
            </div>
            <div className="programa-card-footer">
              <span>Veja os focos estratégicos</span>
            </div>
          </div>
        </ModalArea>

        <ModalArea>
          <div
            className="click programa-green-card"
            role="button"
            onClick={() => setModalGreen(true)}
          >
            <div className="card-content">
              <img src={Balanca} alt="" />
              <h4>Justiça Criminal</h4>
            </div>
            <div className="click programa-card-footer">
              Veja os focos estratégicos
            </div>
          </div>
          <div className="margin" />

          <div
            className="click programa-purple-card"
            role="button"
            onClick={() => setModalPurple(true)}
          >
            <div className="card-content">
              <img src={ApertoMao} alt="" />
              <h4>Reinserção Social</h4>
            </div>
            <div className="programa-card-footer">
              Veja os focos estratégicos
            </div>
          </div>
          <div className="margin" />

          <div
            className="click programa-blue-card"
            role="button"
            onClick={() => setModalBlue(true)}
          >
            <div className="card-content">
              <img src={Lampada} alt="" />
              <h4>Gestão de Modernização Institucional</h4>
            </div>
            <div className="programa-card-footer">
              Veja os focos estratégicos
            </div>
          </div>
        </ModalArea>

        {modalYellow && (
          <Modal
            handleClose={() => setModalYellow(false)}
            show={modalYellow}
            noHeader
          >
            <h2 className="modal-title yellow">Prevenção Social</h2>
            <p className="modal-text">
              Programas de empoderamento das mulheres para redução da violência
              de gênero.
            </p>
            <p className="modal-text">
              Programas de proteção contra a violência e/ou negligência no
              ambiente familiar.
            </p>
            <p className="modal-text">
              Programas de promoção de desenvolvimento cognitivo e êxito
              escolar.
            </p>
            <p className="modal-text">
              Programas de desenvolvimento socioemocional para prevenção de
              condutas de risco.
            </p>
            <p className="modal-text">
              Programas de acesso a serviços de atenção e prevenção da
              revitimização.
            </p>
            <p className="modal-text">
              Programas de prevenção da violência entre gangues.
            </p>
            <p className="modal-text">
              Programas de prevenção e redução da violência armada.
            </p>
          </Modal>
        )}

        {modalOrange && (
          <Modal
            handleClose={() => setModalOrange(false)}
            show={modalOrange}
            noHeader
          >
            <h2 className="modal-title orange">Prevenção Situacional</h2>
            <p className="modal-text">Programas de mudança de normas sociais</p>
            <p className="modal-text">
              Programas de proteção contra a violência no ambiente comunitário.
            </p>
            <p className="modal-text">
              Programas de prevenção situacional de crimes contra a propiedade.
            </p>
            <p className="modal-text">
              Estratégias urbanísticas para melhoraria da sensação de segurança.
            </p>
          </Modal>
        )}

        {modalRed && (
          <Modal
            handleClose={() => setModalRed(false)}
            show={modalRed}
            noHeader
          >
            <h2 className="modal-title orange">Policiamento</h2>
            <p className="modal-text">
              Estratégias de policiamento preventivo.
            </p>
            <p className="modal-text">
              Programas de Tecnologia para prevenção e/ou detecção de crimes.
            </p>
            <p className="modal-text">Estratégias policiais disruptivas.</p>
            <p className="modal-text">Estrategias policiais investigativas.</p>
            <p className="modal-text">Fortalecimento da análise criminal.</p>
            <p className="modal-text">
              Estratégias proativas de fiscalização integrada.
            </p>
            <p className="modal-text">Estratégias de resposta rápida.</p>
          </Modal>
        )}

        {modalGreen && (
          <Modal
            handleClose={() => setModalGreen(false)}
            show={modalGreen}
            noHeader
          >
            <h2 className="modal-title green">Justiça criminal</h2>
            <p className="modal-text">
              Programas de alternativas penais e monitoramento eletrônico.
            </p>
            <p className="modal-text">
              Programas de alternativas penais para adolescentes.
            </p>
            <p className="modal-text">
              Programas de acesso a direitos e à justiça
            </p>
          </Modal>
        )}

        {modalPurple && (
          <Modal
            handleClose={() => setModalPurple(false)}
            show={modalPurple}
            noHeader
          >
            <h2 className="modal-title purple">Reinserção Social</h2>
            <p className="modal-text">
              Programas de gestão dos riscos e necessidades individuais dos
              privados de liberdade.
            </p>
            <p className="modal-text">
              Programas de reinserção e articulação com a sociedade.
            </p>
            <p className="modal-text">
              Modernização de sistemas de segurança, informação e inteligência
              para o sistema prisional.
            </p>
            <p className="modal-text">
              Modernização de sistemas de segurança, informação e inteligência
              para o sistema socioeducativo.
            </p>
            <p className="modal-text">
              Programas de tratamento de saúde mental, adição de álcool e
              drogas, e comportamentos antissociais para indivíduos privados de
              liberdade.
            </p>
            <p className="modal-text">
              Programas de modernização/revitalização infraestrutura
              penitenciária para reinserção social de indivíduos privados de
              liberdade.
            </p>
            <p className="modal-text">
              Programas de modernização/revitalização da infraestrutura dos
              centros socioeducativos para a reinserção social de adolescentes
              em conflitos com a lei.
            </p>
          </Modal>
        )}

        {modalBlue && (
          <Modal
            handleClose={() => setModalBlue(false)}
            show={modalBlue}
            noHeader
          >
            <h2 className="modal-title blue">Gestão</h2>
            <p className="modal-text">Fortalecimento do planejamento.</p>
            <p className="modal-text">Programas de gestão por resultados.</p>
            <p className="modal-text">Fortalecimento da governança.</p>
            <p className="modal-text">Programas de gestão integrada.</p>
            <p className="modal-text">
              Fortalecimento dos mecanismos de controle das polícias.
            </p>
            <p className="modal-text">
              Estratégias de comunicação sobre segurança.
            </p>
            <p className="modal-text">
              Fortalecimento diagnostico e avaliação de politicas.
            </p>
            <p className="modal-text">
              Programas de fortalecimento da gestão penitenciária e seus
              funcionários
            </p>
            <p className="modal-text">
              Programas de fortalecimento da gestão do sistema socioeducativo e
              seus funcionários
            </p>
          </Modal>
        )}
      </Content>
    </Container>
  );
}

export default Estrutura;
