import React, { useState, useCallback } from 'react';
import { useLoader } from '../../hooks/loader';
import Loader from '../../components/Loader';
import { useAuth } from '../../hooks/auth';
import { FaPowerOff, FaRegUserCircle } from 'react-icons/fa';
import CommomAside from './Aside';
import AdminAside from './AdminAside';

import { GridContainer, Header, Main, MainOverview, Footer } from './styles';

const DashboardLayout = ({ children, title }) => {
  const [menuActive, setMenuActive] = useState(false);
  const { isActive } = useLoader();
  const { user, signOut } = useAuth();

  const haldleSetMenu = useCallback(() => {
    setMenuActive(!menuActive);
  }, [menuActive]);

  return (
    <>
      {!!Object.keys(user).length ? (
        <GridContainer active={menuActive}>
          <Header>
            <div className="menu-icon" onClick={haldleSetMenu}>
              <strong> &#9776;</strong>
            </div>
            <div className="header-wrap">
              <h1>{title}</h1>
              <div className="profile">
                {!user.photo ? (
                  <>
                    <FaRegUserCircle size={28} className="userImg" />
                    <a className="mobilePhoto" href="/platform/perfil/">
                      <FaRegUserCircle size={28} />
                    </a>
                  </>
                ) : (
                  <>
                    <img src={user.photo} alt="" className="userImg" />
                    <a className="mobilePhoto" href="/platform/perfil/">
                      <img src={user.photo} alt="" />
                    </a>
                  </>
                )}
                <div className="profile-data">
                  <h3>
                    <a href="/platform/perfil/">
                      {user.full_name}
                      <span>Meu Perfil</span>
                    </a>
                  </h3>
                </div>
                <form class="logout-form" method="post">
                  <button
                    type="submit"
                    className="nav-link text-dark font-weight-bold btn-logout"
                    onClick={signOut}
                  >
                    <FaPowerOff />
                    Sair
                  </button>
                </form>
              </div>
            </div>
          </Header>
          {user.is_admin ? (
            <AdminAside setMenu={haldleSetMenu} />
          ) : (
            <CommomAside setMenu={haldleSetMenu} />
          )}
          <Main>
            {isActive ? <Loader /> : <MainOverview>{children}</MainOverview>}
          </Main>
          <Footer></Footer>
        </GridContainer>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DashboardLayout;
