import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container } from './styles';

const Radio = ({ name, options }) => {
  const inputRefs = useRef([]);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      path: undefined,
      getValue(refs) {
        const checked = refs.find(ref => ref.checked);
        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === value);
        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {options.map((option, index) => (
        <label htmlFor={name} key={option.id}>
          <input
            ref={elRef => (inputRefs.current[index] = elRef)}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue === option.id}
          />

          <span className="checkmark" />
          <span className="labelText">{option.label}</span>
        </label>
      ))}
    </Container>
  );
};
export default Radio;
