import styled from 'styled-components';

export const Container = styled.div`
  .TabsContainer {
    border-bottom: 5px solid #04547c;

    :after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 15px 15px 0px 15px;
      border-color: #19547c transparent transparent transparent;
      display: inline-block;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 0.3s ease;

      left: 0;
      right: 0;
      margin-left: ${props =>
        props.tab === '1'
          ? '15%'
          : props.tab === '2'
          ? '49%'
          : props.tab === '3' && '82%'};
    }
  }

  .TextContainer {
    h1 {
      text-align: left;
      font: normal normal bold 23px/30px IBM Plex Sans;
      letter-spacing: 0px;
      color: #242424;
    }

    p {
      text-align: left;
      font: normal normal normal 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: #6d6e71;
      margin-bottom: 15px;
    }

    h2 {
      text-align: left;
      font: normal normal bold 25px/33px IBM Plex Sans;
      letter-spacing: 0px;
      color: #075e89;
      opacity: 1;
    }

    small {
      text-align: left;
      text-decoration: underline;
      font: normal normal normal 16px/20px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: #032635;
      }
    }
  }

  .legendContent {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
    }

    img {
      width: 18px;
    }
  }
`;

export const BackButton = styled.button`
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 20px/25px IBM Plex Sans;
  color: #004e70;
  margin: 7px 0;
  background: transparent;
`;

export const TabButton = styled.button`
  position: relative;
  background: var(--white);
  width: 100%;
  height: 210px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: ${props =>
    props.color ? `1px solid ${props.color}` : `1px solid #007bac`};
  border-radius: 20px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;

  span {
    text-align: center;
    font: normal normal bold 17px/22px IBM Plex Sans;
    letter-spacing: 0px;
    color: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;
  }

  div {
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    bottom: 0;
    width: 100%;
    height: 54px;
    background: ${props => (props.color ? `${props.color}` : `#007bac`)};
    opacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      font: normal normal bold 20px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #f8f8f8;
      opacity: 1;
    }
  }
`;

export const InfoTitleContainer = styled.div`
  h1 {
    text-align: left;
    font: normal normal bold 45px/58px IBM Plex Sans;
    letter-spacing: 0px;
    color: #00a9ee;
    margin-bottom: 10px;
  }

  .ticketsContainer {
    span {
      width: 106px;
      height: 29px;
      padding: 7px;
      border-radius: 11px;
      color: var(--white);
    }

    .ranking {
      background: #00a9ee 0% 0% no-repeat padding-box;
      margin-right: 5px;
    }

    .nota {
      background: #8d9a9f 0% 0% no-repeat padding-box;
    }
  }
`;

export const TitleButton = styled.div`
  background: #075e89;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  color: var(--white);
  text-align: left;
  font: normal normal bold 23px/30px IBM Plex Sans;
  margin-top: 18px;
  margin-bottom: 31px;

  small {
    text-align: left;
    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  h1 {
    text-align: left;
    font: normal normal bold 23px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;

export const CardsContainer = styled.div`
  h1 {
    text-align: center;
    font: normal normal bold 50px/65px IBM Plex Sans;
    letter-spacing: 0px;
    color: #075e89;
    opacity: 1;
  }

  .emptyMessage {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-weight: bold;
  }
`;

export const ImpactCard = styled.div`
  min-height: 256px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #075e89;
  border-radius: 5px;
  padding: 10px;

  .headContent {
    border-bottom: 2px solid #c9cbd2;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    display: flex;
    padding-bottom: 10px;
    height: 154px;

    .impactContainer {
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h1 {
        text-align: left;
        font: normal normal bold 15px/19px IBM Plex Sans;
        letter-spacing: 0px;
        color: #075e89;
        margin-bottom: 15px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      small {
        font: normal normal normal 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      background: #04547c;
      border-radius: 8px;
      padding: 15px;
      transition: background 0.3s ease;

      &:hover {
        background: #033047;
      }
    }
  }

  .IconContent {
    display: flex;
    margin-top: 15px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${props => `1px solid ${props.color}`};
  width: ${props => (props.legend ? `30px` : '57px')};
  height: ${props => (props.legend ? `30px` : '57px')};
  padding: ${props => props.legend && '5px'};
  margin-top: ${props => props.legend && '10px'};
  border-radius: 8px;
  opacity: 1;
  margin-right: 10px;

  img {
    width: 41px;
  }

  svg {
    transform: ${props => (props.rotate ? `rotate(180deg)` : 'rotate(0deg)')};
    path {
      fill: ${props => props.color};
    }
  }
`;

export const LengendContainer = styled.div`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 21px;

  h1 {
    text-align: left;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #04547c;
    margin-bottom: 10px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    text-align: left;
    font: normal normal bold 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;
  }
`;
