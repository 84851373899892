import React, { useState, useEffect, useCallback } from 'react';
import api from '../../../../services/api';
import { useHistory } from 'react-router-dom';
import ModalLoader from '../../../../components/ModalLoader';

import { Container, Card, ImpactCard } from './styles';
import { Row, Column } from '../../../../styles/components';

const ImpactIndicator = () => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [operations, setOperations] = useState([]);

  const getOperations = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('credits/api/v1/monitoring-impact/');
      setOperations(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [setLoading]);

  useEffect(() => {
    getOperations();
  }, [getOperations]);

  return (
    <Container>
      <Card shadow>
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <h2>
              Selecione a operação de crédito desejada para visualizar os
              indicadores de impacto correspondentes
            </h2>

            <Row>
              {operations.map(op => (
                <Column key={op.code} small="12" medium="6" large="4">
                  <ImpactCard
                    onClick={() =>
                      push(
                        `/management/indicadores-impacto-operacao/${op.code}`,
                      )
                    }
                  >
                    <span className="operation-title">Operação de Crédito</span>
                    <span className="operation-code">{`(${op.operation_entity.abbreviation} ${op.code})`}</span>
                    <span className="operation-name">{op.operation_title}</span>
                  </ImpactCard>
                </Column>
              ))}
            </Row>
          </>
        )}
      </Card>
    </Container>
  );
};

export default ImpactIndicator;
