import { createGlobalStyle } from 'styled-components';
import 'react-toggle/style.css';

export default createGlobalStyle`
  *{
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
  }

  :root{
    --white:#ffffff;
    --whitesmoke:#f5f5f5;
    --blue:#0C9FD8;
    --light-blue:#00A9EE;
    --dark-blue: #004E70;
    --dark-blue2:#0B5E83;
    --red: #FD5454;
    --green: #5EB245;
    --dark-green:#067056;
    --yellow: #FFC21D;
    --brown:#C1856F;
    --text:#6D6E71;
    --text2:#9F9F9F;
    --gray:#F1F1F1;
    --gray2:#AAB9BF;
    --gray3:#8D9A9F;
    --light-gray:#F1F1F1;
    --light-gray2:#D0D0D0;
    --light-gray3:#F8F8F8;
    --aside-gradient:transparent linear-gradient(
      180deg,
    #004e70 0%,
    #0b6b9b 100%
    ) 0% 0% no-repeat padding-box;
  }

  @media(max-width: 1080px){
    html {
      font-size:93.75%;
    }
  }

  @media(max-width: 720px){
    html {
      font-size:81.45%;
    }
  }

  body{
    background:var(--white);
    color:var(--text);
    -webkit-font-smoothing: antialiased;
  }

  body,input,text-area,button{
    font: 400 1rem 'IBM Plex Sans', sans-serif;
  }

  button {
    cursor:pointer;
    border:0;
  }

  a{
    text-decoration:none;
    color:inherit;
  }

  h1{
    font: 600 2rem 'IBM Plex Sans', sans-serif;
  }
`;
