import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import ExternalRoutes from './ExternalRoutes';
import { CreditOperationProvider } from '../hooks/creditOperation';

import ManagementMonitoring from '../pages/ManagementMonitoring';
import CreditOperationMonitoring from '../pages/CreditOperationMonitoring';
import CreditOperationMonitoringAdmin from '../pages/CreditOperationMonitoringAdmin';
import ManagementMonitoringAdmin from '../pages/ManagementMonitoringAdmin';
import AddProjects from '../pages/AddProjects';
import AddProjectDeliverables from '../pages/AddProjectDeliverables';
import EditProjectDeliverables from '../pages/EditProjectDeliverables';
import ListCreditOperation from '../pages/ListCreditOperation';
import ListCreditOperationManager from '../pages/ListCreditOperationManager';
import UnderConstruction from '../pages/UnderConstruction';
import FinancialExecution from '../pages/FinancialExecution';
import FinancialExecutionAdmin from '../pages/FinancialExecutionAdmin';
import ImpactsMonitoring from '../pages/ImpactsMonitoring';
import TeachingPlatform from '../pages/TeachingPlatform';
import DocumentRepository from '../pages/DocumentRepository';
import CoreOfKnowledge from '../pages/CoreOfKnowledge';
import BankOfDoubts from '../pages/BankOfDoubts';
import BankOfDoubtsDetail from '../pages/BankOfDoubtsDetail';
import FinalizePreProposal from '../pages/FinalizePreProposal';
import MonitoringPanel from '../pages/MonitoringPanel';
import IndicatorMethodology from '../pages/IndicatorMethodology';
import OperationImpactIndicators from '../pages/OperationImpactIndicators';
import SolutionsList from '../pages/SolutionsList';
import PreProposal from '../pages/PreProposal';
import Dashboard from '../pages/PlatformPages/Dashboard';
import Diagnosis from '../pages/PlatformPages/Diagnosis';
import { SelfEvaluationReport } from '../pages/SelfEvaluationReport';

const routes = () => (
  <>
    <ExternalRoutes />
    <Switch>
      <AdminRoute
        path="/management"
        exact
        component={ManagementMonitoringAdmin}
      />
      <AdminRoute
        path="/management/painel-monitoramento"
        component={MonitoringPanel}
      />
      <AdminRoute
        path="/management/solucoes/:solutionId"
        component={SolutionsList}
      />
      <AdminRoute
        path="/management/pre-proposta/:preProposalId"
        component={PreProposal}
      />
      <AdminRoute
        path="/management/banco-duvidas"
        exact
        component={BankOfDoubts}
      />
      <AdminRoute
        path="/management/banco-duvidas/:topicId"
        exact
        component={BankOfDoubtsDetail}
      />
      <AdminRoute
        path="/management/desenvolvimento"
        component={UnderConstruction}
      />
      <AdminRoute
        path="/management/monitoramento-impactos"
        component={ImpactsMonitoring}
      />
      <AdminRoute
        path="/management/indicadores-impacto-operacao/:operationId"
        exact
        component={OperationImpactIndicators}
      />
      <AdminRoute
        path="/management/metodologia-indicador"
        exact
        component={IndicatorMethodology}
      />
      <AdminRoute
        path="/management/nucleo-conhecimento"
        component={CoreOfKnowledge}
      />
      <AdminRoute
        path="/management/plataforma-ensino"
        component={TeachingPlatform}
      />
      <AdminRoute
        path="/management/repositorio-documentos"
        component={DocumentRepository}
      />
      <AdminRoute
        path="/management/operacoes-credito"
        component={ListCreditOperation}
      />
      <AdminRoute
        path="/management/operacao-credito/:operationCode"
        component={CreditOperationMonitoringAdmin}
      />
      <AdminRoute
        path="/management/execucao-financeira/:operationCode"
        component={FinancialExecutionAdmin}
      />
      <CreditOperationProvider>
        <PrivateRoute path="/projects" exact component={Dashboard} />
        <PrivateRoute
          path="/projects/diagnostico"
          exact
          component={Diagnosis}
        />
        <PrivateRoute
          path="/projects/gestao-monitoramento"
          exact
          component={ManagementMonitoring}
        />
        <PrivateRoute
          path="/projects/inicio"
          exact
          component={ManagementMonitoring}
        />
        <PrivateRoute
          path="/projects/finalizar-pre-proposta"
          exact
          component={FinalizePreProposal}
        />
        <PrivateRoute
          path="/projects/nucleo-conhecimento"
          component={CoreOfKnowledge}
        />
        <PrivateRoute
          path="/projects/plataforma-ensino"
          exact
          component={TeachingPlatform}
        />
        <PrivateRoute
          path="/projects/repositorio-documentos"
          exact
          component={DocumentRepository}
        />
        <PrivateRoute
          path="/projects/execucao-financeira"
          exact
          component={FinancialExecution}
        />
        <PrivateRoute
          path="/projects/monitoramento-impactos"
          exact
          component={ImpactsMonitoring}
        />
        <PrivateRoute
          path="/projects/metodologia-indicador"
          exact
          component={IndicatorMethodology}
        />
        <PrivateRoute
          path="/projects/operacoes-credito"
          exact
          component={ListCreditOperationManager}
        />
        <PrivateRoute
          path="/projects/projetos-contratados"
          exact
          component={AddProjects}
        />
        <PrivateRoute
          path="/projects/desenvolvimento"
          exact
          component={UnderConstruction}
        />
        <PrivateRoute
          path="projects/execucao-financeira"
          exact
          component={FinancialExecution}
        />
        <PrivateRoute
          path="/projects/banco-duvidas"
          exact
          component={BankOfDoubts}
        />
        <PrivateRoute
          path="/projects/banco-duvidas/:topicId"
          exact
          component={BankOfDoubtsDetail}
        />
        <PrivateRoute
          path="/projects/projetos-contratados/:projectId"
          exact
          component={AddProjects}
        />
        <PrivateRoute
          path="/projects/projetos-contratados/:projectId/entregaveis"
          exact
          component={AddProjectDeliverables}
        />
        <PrivateRoute
          path="/projects/projetos-contratados/:projectId/entregaveis/:deliverableId"
          exact
          component={EditProjectDeliverables}
        />
        <PrivateRoute
          path="/projects/monitoramento-credito"
          exact
          component={CreditOperationMonitoring}
        />

        <PrivateRoute
          path="/platform/autoavaliacao/todas-perguntas"
          exact
          component={SelfEvaluationReport}
        />
      </CreditOperationProvider>
    </Switch>
  </>
);

export default routes;
