import styled from 'styled-components';

export const Container = styled.div`
  .modal-main {
    max-width: 768px;
  }

  .modal-header {
    height: 0px !important;
    span {
      display: none;
    }
  }

  .modal-body {
    padding: 0px !important;
  }

  .loginContainer {
    display: flex;
    width: 100%;
    border-radius: 5px;

    .formContainer {
      padding: 24px 16px;
      width: 50%;
      background: #ffffff;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      form {
        width: 93%;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          border-radius: 10px;
          flex-direction: row-reverse;
          background: #f8f8f8;
          border: #f8f8f8;
          height: 45px;
          padding-left: 8px;

          input {
            font-size: 20px;

            &::placeholder {
              color: #757575;
            }
          }

          svg {
            width: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            path {
              fill: #1e90ff;
            }
          }
        }

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }

        .submitContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          flex-direction: row;
          background: transparent;
          margin-top: 45px;
          padding-bottom: 28px;
          border-bottom: 1px solid #a09f9f;
          border-radius: 0px;

          .forgotButton {
            background: transparent;
            border-bottom: 1px solid black;
            font-size: 11px;
          }

          .submit {
            background: #3aa9ee;
            color: #fff;
            padding: 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 0.3s ease;

            svg {
              margin-right: 5px;
              path {
                fill: #fff;
              }
            }

            &:hover {
              background: #2f82b5;
            }
          }
        }
      }
      h1 {
        font-weight: 700 !important;
        color: #212529;
        font-size: 28px;
      }
      .register {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          margin-bottom: 15px;
        }

        button {
          background: #164e70;
          color: #fff;
          font-weight: 700;
          padding: 10px;
          border-radius: 5px;
          transition: background 0.3s ease;

          &:hover {
            background: #144563;
          }
        }
      }
    }

    .infoContainer {
      padding: 60px;
      width: 50%;
      background: transparent linear-gradient(180deg, #004e70 0%, #042c40 100%)
        0% 0% no-repeat padding-box;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      .text {
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.2;
        margin: 15px 0;
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .loginContainer {
      .formContainer {
        width: 100%;
      }

      .infoContainer {
        display: none;
      }
    }
  }
`;
