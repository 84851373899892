import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { MdMenu, MdClose, MdSearch } from 'react-icons/md';
import AsyncAutoComplete from '../AsyncAutoComplete';
import { FaLock, FaSignOutAlt } from 'react-icons/fa';
import { BsFillCaretDownFill } from 'react-icons/bs';
import DropdownButton from '../../components/Dropdownbutton';
import Logo from '../../assets/logo_plataforma_novo.png';
import api from '../../services/api';
import {
  Container,
  NavContainer,
  Content,
  MobileMenu,
  SearchBox,
} from './styles';

function SiteHeader({ transparent }) {
  const { push } = useHistory();
  const { changeLoginModal, isLogged } = useAuth();
  const [menuActive, setMenuActive] = useState(false);
  const [searchMenuActive, setSearchMenuActive] = useState(false);

  const redirect = useCallback(url => {
    window.location.href = url;
  }, []);

  const handleMouseEnter = useCallback(() => {
    setSearchMenuActive(true);
  }, []);

  const handleMouseSearchBoxLeave = useCallback(() => {
    setSearchMenuActive(false);
  }, []);

  const activeSearchBox = useCallback(() => {
    setSearchMenuActive(!searchMenuActive);
  }, [searchMenuActive]);

  const handleMouseLeave = useCallback(event => {
    event.clientY < 64 && setSearchMenuActive(false);
  }, []);

  const ProgramOptions = [
    {
      name: 'Sobre o Programa',
      onClick: () => push('/programa/sobre-o-programa/'),
    },
    {
      name: 'Segurança baseada em evidências',
      onClick: () => push('/programa/seguranca-baseada-em-evidencias/'),
    },
    {
      name: 'Ficha Técnica',
      onClick: () => push('/programa/ficha-tecnica/'),
    },
    {
      name: 'Perguntas e respostas',
      onClick: () => push('/programa/perguntas-e-respostas/'),
    },
  ];

  const boldString = (str, substr) => {
    const keywords = substr.split(' ').join('|');
    return str.replace(new RegExp('(' + keywords + ')', 'gi'), '<b>$1</b>');
  };

  const promiseOptions = inputValue =>
    new Promise(async resolve => {
      if (inputValue.length > 2) {
        const response = await api.get(`v1/locations/?name=${inputValue}`);

        const cityOptions = response.data.results.filter(
          i => !i.is_metropolitan_region && i.type === 'city',
        );
        const StateOptions = response.data.results.filter(
          i => !i.is_metropolitan_region && i.type !== 'city',
        );

        const slicedCityOptions = cityOptions.slice(0, 10);
        const slicedStateOptions = StateOptions.slice(0, 10);

        const groupedCities = slicedCityOptions.map(i => {
          return {
            label: `${boldString(i.text, inputValue)}`,
            value: i.ibge_code,
          };
        });

        const groupedStates = slicedStateOptions.map(i => {
          return {
            label: `${boldString(i.text, inputValue)}`,
            value: i.ibge_code,
          };
        });

        const Options = [
          {
            label: 'Estados',
            options: groupedStates,
          },
          {
            label: 'Cidades',
            options: groupedCities,
          },
        ];
        resolve(Options);
      } else {
        resolve([]);
      }
    });

  return (
    <>
      <Container transparent={transparent}>
        <NavContainer>
          <Content>
            <div className="LogoContainer">
              <img
                src={Logo}
                alt="Plataforma de segurança"
                onClick={() => push('/')}
              />
            </div>
            <div className="ItensContainer">
              <ul className="navbar">
                {isLogged ? (
                  <li
                    className="navbarItem"
                    onClick={() => redirect('/projects')}
                  >
                    <FaSignOutAlt />
                    Entrar
                  </li>
                ) : (
                  <li
                    className="navbarItem"
                    onClick={() => changeLoginModal(true)}
                  >
                    <FaLock />
                    Entrar
                  </li>
                )}
              </ul>
            </div>
            <div
              className="mobileMenuButton"
              onClick={() => setMenuActive(!menuActive)}
            >
              {menuActive ? <MdClose size={30} /> : <MdMenu size={30} />}
            </div>
          </Content>
        </NavContainer>
        <SearchBox
          active={searchMenuActive}
          onMouseLeave={handleMouseSearchBoxLeave}
        >
          <div className="searchBoxContainer">
            <h1>Acesse os dados do seu Estado ou Município</h1>
            <AsyncAutoComplete
              placeholder="Digite o nome da Cidade ou Estado"
              emptyMessage="Nenhuma opção encontrada"
              loadOptions={promiseOptions}
            />
          </div>
        </SearchBox>
      </Container>
      <MobileMenu active={menuActive}>
        <div className="mobileMenuContainer">
          <ul className="navbar">
            <li className="navbarItem" onClick={() => push('/')}>
              Início
            </li>
            <li className="navbarItem">
              <DropdownButton
                color="transparent"
                itens={ProgramOptions}
                padding="2"
                type="button"
                className="dropdownButton"
              >
                O Programa
                <BsFillCaretDownFill />
              </DropdownButton>
            </li>
            <li className="navbarItem" onClick={() => push('/metodologia')}>
              A Metodologia
            </li>
            <button
              className="buttonNav"
              onClick={activeSearchBox}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Busque por Cidade ou Estado
              <MdSearch />
            </button>
            {isLogged ? (
              <li className="navbarItem" onClick={() => redirect('/projects')}>
                <FaSignOutAlt />
                Entrar
              </li>
            ) : (
              <li className="navbarItem" onClick={() => changeLoginModal(true)}>
                <FaLock />
                Entrar
              </li>
            )}
          </ul>
        </div>
      </MobileMenu>
    </>
  );
}

export default SiteHeader;
