import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { useLoader } from '../../hooks/loader';
import Modal from '../../components/Modal';
import ModalLoader from '../../components/ModalLoader';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import DashboardLayout from '../../components/DashboardLayout';
import { FaSearch } from 'react-icons/fa';
import { Row, Column } from '../../styles/components';
import {
  Container,
  SearchButton,
  TotalizerCard,
  ModalContent,
  TableContainer,
} from './styles';

function ListCreditOperation() {
  const [countOperation, setCountOperation] = useState({});
  const [operations, setOperations] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalProjects, setModalProjects] = useState([]);
  const [modalImpacts, setmodalImpacts] = useState([]);
  const [loading, setsetLoading] = useState(false);
  const [modalOperation, setModalOperation] = useState({});
  const [show, setShow] = useState(false);
  const { activeLoader } = useLoader();

  const getCountOperations = useCallback(async () => {
    try {
      const response = await api.get('/credits/api/v1/opcredit-manager/count');
      setCountOperation(response.data);
      activeLoader(false);
    } catch (err) {
      activeLoader(false);
      console.error(err);
    }
  }, [activeLoader]);

  const getOperations = useCallback(async () => {
    try {
      const response = await api.get('/credits/api/v1/opcredit-manager/');
      setOperations(response.data.results);
      activeLoader(false);
    } catch (err) {
      activeLoader(false);
      console.error(err);
    }
  }, [activeLoader]);

  useEffect(() => {
    activeLoader(true);
    getOperations();
    getCountOperations();
  }, [activeLoader, getCountOperations, getOperations]);

  const TableOperationData = useMemo(() => {
    return operations.map(operation => ({
      code: operation.code,
      execution: operation.execution,
      credit_operation: `(${operation.financing_entity} ${operation.code}) ${operation.operation_title}`,
      start_date: `${format(parseISO(operation.start_date), 'dd/MM/yyyy')}`,
      end_date: `${format(parseISO(operation.end_date), 'dd/MM/yyyy')}`,
      global_value: `R$ ${operation.global_value.repre}`,
      status: operation.status,
      operation,
    }));
  }, [operations]);

  const handleSetShowModal = useCallback(async operation => {
    setsetLoading(true);
    setShow(true);

    setModalOperation(operation);
    setModalTitle(operation.operation_title);

    const apiUrl = `/credits/api/v1/opcredit-manager/${operation.code}/desired-impacts/`;
    const response = await api.get(apiUrl);
    setmodalImpacts(response.data.results);

    const projectsApiUrl = `/credits/api/v1/contracted-project/?operation=${operation.code}`;
    const Projectsresponse = await api.get(projectsApiUrl);
    const unresolvedPromises = Projectsresponse.data.results.map(
      async project => {
        const responsibles = await api.get(
          `/credits/api/v1/contracted-project/${project.id}/responsible/`,
        );
        const filteredResponsibles = responsibles.data.results.filter(
          resp => resp.contract_project === Number(project.id),
        );
        project.responsibles = filteredResponsibles;
        return project;
      },
    );
    const promisesResponse = await Promise.all(unresolvedPromises);
    setModalProjects(promisesResponse);
    setsetLoading(false);
  }, []);

  const columns = [
    {
      name: '',
      selector: 'credit_operation',
      maxWidth: '35%',
    },
    {
      name: 'Data Inicial',
      selector: 'start_date',
      maxWidth: '20%',
    },
    {
      name: 'Data Final',
      selector: 'end_date',
      maxWidth: '20%',
    },
    {
      name: 'Valor',
      selector: 'global_value',
      maxWidth: '20%',
    },
    {
      name: 'Ver Mais',
      selector: 'code',
      width: '100px',
      cell: row => (
        <SearchButton onClick={() => handleSetShowModal(row.operation)}>
          <FaSearch size={18} color="#ffffff" />
        </SearchButton>
      ),
    },
  ];

  return (
    <Container>
      <DashboardLayout title="Monitoramento das Operações de Crédito">
        <Row>
          <Column small="12" medium="6" large="6">
            <TotalizerCard color="blue">
              <h1>{countOperation.count_operation}</h1>
              <small>Operações de Crédito</small>
            </TotalizerCard>
          </Column>
          <Column small="12" medium="6" large="6">
            <TotalizerCard color="dark-blue">
              <h1>{`R$ ${countOperation.representation_value}`}</h1>
              <small>Valor Total Contratado</small>
            </TotalizerCard>
          </Column>
        </Row>
        <TableContainer>
          <DataTable
            columns={columns}
            data={TableOperationData}
            noDataComponent=""
            noHeader
          />
        </TableContainer>

        <Modal
          title={modalTitle}
          success={false}
          successText=""
          handleClose={() => setShow(false)}
          show={show}
          disableInfo
        >
          <ModalContent>
            {loading ? (
              <ModalLoader />
            ) : (
              <>
                <h1>Objetivo Geral</h1>
                <p>{modalOperation.gen_goal}</p>

                <h1>Impactos Desejados</h1>
                {modalImpacts.map(impacto => (
                  <div key={impacto.id}>
                    <span>{impacto.impact_indicator.impact_name}</span>
                  </div>
                ))}

                <h1>Projetos Contratados</h1>
                {modalProjects.map(project => (
                  <div key={project.id}>
                    <span>{project.title}</span>
                  </div>
                ))}
              </>
            )}
          </ModalContent>
        </Modal>
      </DashboardLayout>
    </Container>
  );
}

export default ListCreditOperation;
