import React from 'react';
import { Route } from 'react-router-dom';
import Home from '../pages/ExternalPages/Home';
import DiagnosticoIndicadores from '../pages/ExternalPages/DiagnosticoIndicadores';
import ExecutiveReport from '../pages/ExternalPages/ExecutiveReport';
import ExecutiveReport2 from '../pages/ExternalPages/ExecutiveReport2';
import ExecutiveReport3 from '../pages/ExternalPages/ExecutiveReport3';
import ExecutiveReport4 from '../pages/ExternalPages/ExecutiveReport4';
import SobrePrograma from '../pages/ExternalPages/SobrePrograma';
import SegurancaEvidencia from '../pages/ExternalPages/SegurancaEvidencias';
import FichaTecnica from '../pages/ExternalPages/FichaTecnica';
import PerguntasFrequentes from '../pages/ExternalPages/PerguntasFrequentes';
import Methodology from '../pages/ExternalPages/Methodology';
import SelectSolutions from '../pages/ExternalPages/SelectSolutions';
import MaturityChecklist from '../pages/ExternalPages/MaturityChecklist';
import MethodologyDiagnosis from '../pages/ExternalPages/MethodologyDiagnosis';
import IndicatorMethodology from '../pages/ExternalPages/IndicatorMetodology';
import DetailForm from '../pages/ExternalPages/DetailForm';
import DiagnosticIndicatorDetail from '../pages/ExternalPages/DiagnosticIndicatorDetail';

const ExternalRoutes = () => {
  return (
    <>
      <Route path="/" exact component={SobrePrograma} />
      <Route path="/programa/sobre-o-programa" component={SobrePrograma} />
      <Route
        path="/programa/seguranca-baseada-em-evidencias"
        component={SegurancaEvidencia}
      />
      <Route path="/programa/ficha-tecnica" component={FichaTecnica} />
      <Route
        path="/programa/perguntas-e-respostas"
        component={PerguntasFrequentes}
      />
      <Route path="/metodologia" exact component={Methodology} />
      <Route
        path="/metodologia/diagnostico-de-indicadores"
        exact
        component={MethodologyDiagnosis}
      />
      <Route
        path="/metodologia/selecao-de-solucoes"
        exact
        component={SelectSolutions}
      />
      <Route
        path="/metodologia/checklist-de-maturidade"
        exact
        component={MaturityChecklist}
      />
      {/* <Route
        path="/metodologia/indicador-sintetico"
        exact
        component={IndicatorMethodology}
      /> */}
      <Route
        path="/metodologia/formulario-de-detalhamento"
        exact
        component={DetailForm}
      />
      <Route
        path="/diagnostico-indicadores/"
        exact
        component={DiagnosticoIndicadores}
      />
      <Route
        path="/diagnostico-indicadores/:ibgeCode"
        exact
        component={DiagnosticoIndicadores}
      />
      <Route
        path="/diagnostico-indicadores/:ibgeCode/:indicatorId"
        exact
        component={DiagnosticIndicatorDetail}
      />
      <Route
        path="/relatorio-executivo/:locationId"
        exact
        component={ExecutiveReport}
      />
      <Route
        path="/relatorio-executivo-2/:operationId"
        exact
        component={ExecutiveReport3}
      />
      <Route
        path="/relatorio-executivo-3/:projectId"
        exact
        component={ExecutiveReport4}
      />
      <Route
        path="/relatorio-executivo-global"
        exact
        component={ExecutiveReport2}
      />
    </>
  );
};

export default ExternalRoutes;
