import styled from 'styled-components';

export const Container = styled.div`
  .rdt_Table {
    margin-top: 25px;
  }

  .rdt_TableCol {
    font: normal normal bold 14px/18px IBM Plex Sans;
    background: #1d5e89;
    color: #ffffff;
    text-align: center;
    border-left: 1px solid #d0d0d0;
  }

  .rdt_TableCell {
    text-align: left;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    min-height: 44px;

    & > div {
      white-space: inherit;
    }
  }

  .fileButtonContainer {
    display: flex;

    margin-top: -30px;

    div {
      margin-right: 10px;
    }

    button {
      background: #1d5e89 !important;
      height: 61px !important;
      width: 100% !important;
      margin-right: 10px !important;
      border-radius: 5px !important;
      padding: 15px !important;
      height: 45px !important;
      text-align: center !important;
    }

    .downloadDiv {
      display: flex;
      flex-direction: column;
      span {
        font-weight: bold;
      }
      a {
        color: #1d5e89;
        &:hover {
          color: #07293f;
        }
      }
    }
  }
`;

export const BackButton = styled.button`
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 20px/25px IBM Plex Sans;
  color: #004e70;
  margin-top: 15px;
  background: transparent;
  margin-right: 20px;
  display: ${props => (props.display ? 'none' : 'block')};
  height: ${props => (props.display ? '0' : 'auto')};
`;

export const Title = styled.h1`
  margin-top: 10px;
  text-align: left;
  font: normal normal 600 30px/39px IBM Plex Sans;
  letter-spacing: 0px;
  color: #004e70;
  opacity: 1;
`;

export const SubTitle = styled.h1`
  margin: 15px auto;
  color: #6d6e71;
  font: normal normal normal 20px IBM Plex Sans;
`;

export const Card = styled.section`
  margin-top: 10px;
  background: ${props => (props.color ? `${props.color}` : `var(--white)`)};
  border-radius: 10px;
  overflow: auto;
  padding: 15px;
  width: 100%;
  margin-bottom: 25px;

  span {
    text-align: left;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #6d6e71;
    opacity: 1;
  }

  p {
    text-align: left;
    font: normal normal bold 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #d0d0d0;
    opacity: 1;
    margin-bottom: 15px;
  }
`;

export const FormSectionContainer = styled.section`
  h1 {
    color: #6d6e71;
    display: flex;
    align-items: center;
    font: normal normal normal 20px IBM Plex Sans;
  }

  small {
    color: #6d6e71;
    display: flex;
    font: normal normal normal 14px IBM Plex Sans;
  }

  .input-container {
    display: flex;

    & > div {
      margin-right: 15px;
      margin-top: 0px;
    }
    button {
      min-width: 50px;
      height: 50px;
      border-radius: 10px;
      background: #5eb245;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;
      color: #ffffff;
      font-weight: bold;

      &:hover {
        background: #34a911;
      }
    }
  }

  .itens-row {
    margin-top: -25px;
  }

  .noOptionsMessage {
  }
`;

export const SecretariesContainer = styled.section`
  h1 {
    color: #6d6e71;
    font: normal normal normal 20px IBM Plex Sans;
  }

  .input-container {
    display: flex;

    & > div {
      margin-right: 15px;
      margin-top: 0px;
    }
    button {
      min-width: 50px;
      height: 50px;
      border-radius: 10px;
      background: #5eb245;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;

      &:hover {
        background: #34a911;
      }
    }
  }

  .itens-row {
    margin-top: -25px;
  }

  .noOptionsMessage {
  }
`;

export const DeleteButton = styled.button`
  background: var(--red);
  color: #ffffff;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  opacity: ${props => (props.display ? '0' : '1')};

  &:hover {
    background: #ef3030;
  }
`;

export const PrintableBodyWrapper = styled.div``;

export const PrintableDiv = styled.div`
  display: ${props => (props.display ? 'block' : 'none')};
  height: ${props => (props.display ? 'auto' : '0px')};
  margin-bottom: ${props => (props.display ? '15px' : '0px')};
  margin-top: ${props => (props.display ? '15px' : '0px')};

  span {
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;
  }
`;

export const FormButton = styled.button`
  opacity: ${props => (props.display ? '0' : '1')};
  background: #075e89 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal bold 16px/20px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 8px;
  padding: 13px;
  transition: background 0.3s ease;

  &:hover {
    background: #084563;
  }
`;

export const ImpactError = styled.button`
  color: var(--red);
  margin-bottom: 10px;
  background: var(--white);
`;

export const SolutionsContainer = styled.div`
  h1 {
    color: #6d6e71;
    display: flex;
    align-items: center;
    font: normal normal normal 20px IBM Plex Sans;
  }
`;

export const TotalContainer = styled.div`
  height: 58px;
  background: #063c6e 0% 0% no-repeat padding-box;

  text-align: left;
  font: normal normal bold 25px/33px IBM Plex Sans;
  letter-spacing: 0px;
  color: #ffffff;

  display: flex;
  align-items: center;
  padding: 0 15px;
`;
