import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import Cookie from 'js-cookie';
import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [showLoginModal, setLoginModal] = useState(false);
  const [showForgotModal, setForgotModal] = useState(false);
  const [showRegistrationModal, setRegistrationModal] = useState(false);
  const [isLogged, setIsLogged] = useState(() => {
    return !!Cookie.get('sessionid');
  });

  const getUser = async () => {
    const response = await api.get('/accounts/my-profile/');
    setUser(response.data);
  };

  useEffect(() => {
    isLogged && getUser();
  }, [isLogged]);

  const signOut = useCallback(() => {
    Cookie.remove('sessionid');
    setIsLogged(false);
    window.location.href = '/';
  }, []);

  const changeLoginModal = useCallback(data => {
    setLoginModal(data);
  }, []);

  const changeForgotModal = useCallback(data => {
    setForgotModal(data);
  }, []);

  const changeRegistrationModal = useCallback(data => {
    setRegistrationModal(data);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        showLoginModal,
        changeLoginModal,
        showForgotModal,
        changeForgotModal,
        showRegistrationModal,
        changeRegistrationModal,
        isLogged,
        signOut,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth só pode ser usado dentro de um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
