import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import PrintLoader from '../../../components/PrintLoader';
import DonutChart from '../../../components/DonutChart';
import MultiLine from './graphics/MultiLine';
import { printDocumentPage, getPageCanvas, formatDate } from './reportService';
import { IoIosArrowForward } from 'react-icons/io';
import DataTable from 'react-data-table-component';
import {
  Container,
  Header,
  HeaderContent,
  Main,
  MainContent,
  PrintDiv,
  OperationCard,
  HeaderCard,
  Card,
  Title,
  GraphicsContainer,
  DonutContainer,
  EntregableContainer,
  StatusSpan,
} from './styles';
import { Row, Column } from '../../../styles/components';

const ExecutiveReport4 = () => {
  const printRef = useRef(null);
  const { projectId } = useParams();
  const [reportData, setReportData] = useState({});
  const [printing, setPrinting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`credits/api/v1/report/project/${projectId}`).then(response => {
      setReportData(response.data);
      setLoading(false);
    });
  }, []);

  function toFixed(num, fixed) {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  }

  const getResultAreaVerbose = color => {
    const status = {
      gestao: 'Gestão e Modernização Institucional',
      policiamento: 'Policiamento',
      prevencao_social: 'Prevenção Social',
      prevencao_situacional: 'Prevenção Situacional',
      reinsercao_social: 'Reinserção Social',
      justica_criminal: 'Justiça Criminal',
    };
    return status[color];
  };

  const getStatusName = name => {
    const status = {
      execution: 'Em execução',
      finalized: 'Concluído',
      delay: 'Em atraso',
      restriction: 'Com restrição',
      delay_restriction: 'Em atraso e com restrição',
    };
    return status[name];
  };

  const getStatusColor = color => {
    const status = {
      execution: '#5CB24B',
      finalized: '#014D90',
      delay: '#EB5B23',
      restriction: '#F7A643',
      delay_restriction: '#C1856F',
    };

    return status[color];
  };

  const columns = [
    {
      name: 'Marco crítico',
      selector: 'name',
    },
    {
      name: 'Status',
      selector: 'status',
      cell: row => <StatusSpan color={getStatusColor(row.status)} />,
    },
    {
      name: 'Entrega final',
      selector: 'deadline',
      cell: row => (
        <div>{formatDate(new Date(row.deadline), 'dd/mm/YYYY')}</div>
      ),
    },
  ];

  const restrictionColumns = [
    {
      name: 'Restrição',
      selector: 'restriction',
    },
    {
      name: 'Ações requeridas',
      selector: 'providence',
    },
    {
      name: 'Prazo',
      selector: 'deadline_providence',
      cell: row => (
        <div>{formatDate(new Date(row.deadline_providence), 'dd/mm/YYYY')}</div>
      ),
    },
  ];

  const getMultilineData = useCallback(data => {
    return [
      {
        percentage: toFixed(data.execution, 2),
        color: '#5CB24B',
      },
      {
        percentage: toFixed(data.finalized || data.finish, 2),
        color: '#014D90',
      },
      {
        percentage: toFixed(data.delay, 2),
        color: '#EB5B23',
      },
      {
        percentage: toFixed(data.restriction, 2),
        color: '#F7A643',
      },
      {
        percentage: toFixed(data.delay_restriction, 2),
        color: '#C1856F',
      },
    ];
  }, []);

  const printDocument = useCallback(async () => {
    window.scrollTo(0, 0);
    setPrinting(true);
    setTimeout(async () => {
      const page = await getPageCanvas(printRef.current);
      const pdf = new jsPDF('pt', 'mm');
      await printDocumentPage(page, pdf, false);
      window.open(pdf.output('bloburl'));
      setPrinting(false);
    }, 500);
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <PrintDiv printing={printing} ref={printRef}>
          {printing && <PrintLoader />}
          <Header>
            <HeaderContent printing={printing}>
              <div className="optionsContainer">
                <button
                  className="back"
                  type="button"
                  onClick={() => printDocument()}
                >
                  Imprimir
                </button>
              </div>
              <div className="content">
                <div className="textContent">
                  <h1>Relatório Executivo</h1>
                  <h2>Visão Por Operação</h2>
                </div>
                <OperationCard printing={printing}>
                  {`Operação de crédito: (${reportData.credit_operation.operation_entity.abbreviation} ${reportData.credit_operation.code}) ${reportData.credit_operation.operation_title}`}
                </OperationCard>
                <OperationCard
                  printing={printing}
                  project
                >{`Projeto: ${reportData.title}`}</OperationCard>

                <Row>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="mb0 flex-column"
                  >
                    <HeaderCard printing={printing}>
                      Responsável:
                      {reportData.responsible_project.length && (
                        <b> {reportData.responsible_project[0].name}</b>
                      )}
                    </HeaderCard>
                  </Column>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="mb0 flex-column"
                  >
                    <HeaderCard printing={printing}>
                      Principal Secretaria/Órgão Responsável:
                      {reportData.departament_project.length && (
                        <b> {reportData.departament_project[0].name}</b>
                      )}
                    </HeaderCard>
                  </Column>
                  <Column
                    small="4"
                    medium="4"
                    large="4"
                    className="mb0 flex-column"
                  >
                    <HeaderCard printing={printing}>
                      Área de resultado
                      <b> {getResultAreaVerbose(reportData.result_area)}</b>
                    </HeaderCard>
                  </Column>
                </Row>
              </div>
            </HeaderContent>
          </Header>
          <Main>
            <MainContent>
              <Title>
                <div className="iconContainer">
                  <IoIosArrowForward color="#ffffff" size={18} />
                </div>
                {/* Visão geral do Projeto */}
                Visão geral da operação de crédito
              </Title>
              <Row>
                {/* <Column small="6" medium="6" large="6" className="mb0">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>Execução Física</h1>
                      <div className="graphs">
                        <DonutChart
                          value={toFixed(reportData.execution.physics, 2)}
                          color="#075E89"
                          size={150}
                          strokewidth={13}
                          type="percent"
                          large
                        />
                      </div>
                    </DonutContainer>
                  </Card>
                </Column> */}
                <Column small="6" medium="6" large="6" className="mb0">
                  <Card printing={printing}>
                    <DonutContainer>
                      <h1>Tempo</h1>
                      <div className="graphs">
                        <DonutChart
                          value={reportData.execution.time_month.total}
                          color="#075E89"
                          size={150}
                          strokewidth={13}
                          month
                          large
                        />
                      </div>
                    </DonutContainer>
                  </Card>
                </Column>
              </Row>

              <Card printing={printing}>
                <Row>
                  <Column
                    small="6"
                    medium="6"
                    large="6"
                    className="border-dashed"
                  >
                    <GraphicsContainer>
                      <h1>Status das entregas(%)</h1>
                      <MultiLine
                        data={getMultilineData(reportData.status_entregables)}
                        disableInfo={false}
                      />
                    </GraphicsContainer>
                  </Column>
                  <Column small="6" medium="6" large="6">
                    <GraphicsContainer>
                      <h1>Status dos marcos críticos(%)</h1>
                      <MultiLine
                        data={getMultilineData(reportData.status_critical)}
                        disableInfo={false}
                      />
                    </GraphicsContainer>
                  </Column>
                </Row>
              </Card>
              {!!reportData.entregable_next.length && (
                <Card printing={printing}>
                  {reportData.entregable_next.map(entregable => (
                    <EntregableContainer className="graphContainer">
                      <div className="titleContainer">
                        {`${entregable.title} - `}
                        <span>{`Status: ${getStatusName(
                          entregable.status,
                        )}`}</span>
                      </div>
                      <DataTable
                        columns={columns}
                        data={entregable.critical}
                        noHeader
                      />
                    </EntregableContainer>
                  ))}
                </Card>
              )}
              {!reportData.entregable_next.length && (
                <Card printing={printing}>
                  Nenhuma Entrega Cadastrada para os próximos 3 meses
                </Card>
              )}
              {!!reportData.restriction.length && (
                <Card printing={printing}>
                  <DataTable
                    columns={restrictionColumns}
                    data={reportData.restriction}
                    noHeader
                  />
                </Card>
              )}
              {!reportData.restriction.length && (
                <Card printing={printing}>Nenhuma Restrição Cadastrada</Card>
              )}
            </MainContent>
          </Main>
        </PrintDiv>
      )}
    </Container>
  );
};

export default ExecutiveReport4;
